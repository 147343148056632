import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcMyLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.5 3v-.5h-1v6h1V3m-8 14.122V12h15v9h1v-9.25a.75.75 0 0 0-.75-.75H4.25a.75.75 0 0 0-.75.75V21h1v-3.878"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcMyLight;
