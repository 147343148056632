import { ChevronRightBoldIcon, HeartBoldFillIcon, StarBoldFillIcon } from '@29cm/contexts-common-icon';
import { commaizeNumber } from '@29cm/contexts-common-utils';
import { MouseEventHandler } from 'react';
import { tv, type VariantProps } from 'tailwind-variants';
import { Badge } from '../Badge';

const productCardInfo = tv({
  slots: {
    base: 'space-y-12',
    header: 'space-y-6',
    requiredInfo: 'mr-10 space-y-2',
    brandName: 'mb-2 flex items-center gap-2 align-baseline text-xs-bold',
    productName: 'mb-6 text-s',
    priceInfo: 'flex items-center gap-2',
    discount: 'text-l-bold text-accent',
    metaDataGroup: 'text-tertiary',
    metaData: 'flex flex-wrap gap-2',
    amountText: 'text-tertiary',
  },
  variants: {
    productNameLineClamp: {
      1: {
        productName: 'line-clamp-1',
      },
      2: {
        productName: 'line-clamp-2',
      },
    },
    hasPadding: {
      true: 'mx-10',
    },
    size: {
      small: {
        base: 'space-y-8',
        header: 'space-y-6',
        requiredInfo: 'mr-20 space-y-2',
        brandName: 'mb-2 text-xs-bold',
        productName: 'mb-2 text-s',
        priceInfo: 'flex gap-2 text-l-bold',
        metaDataGroup: 'space-y-12',
        amountText: 'text-xxs-medium',
      },
      medium: {
        base: 'space-y-10',
        requiredInfo: 'mr-20 space-y-2',
        brandName: 'mb-2 text-m-bold',
        productName: 'mb-6 text-l',
        priceInfo: 'flex gap-2 text-xxl-bold',
        metaDataGroup: 'space-y-[14px]',
        amountText: 'text-xs-medium',
      },
    },
    soldOut: {
      true: {
        discount: 'text-disabled',
        priceInfo: 'text-disabled',
      },
    },
  },
  defaultVariants: {
    size: 'small',
    soldOut: false,
  },
});

type ProductCardInfoVariants = VariantProps<typeof productCardInfo>;

type RequiredInfo = {
  productName: string;
  price: number;
};

type MetaData = {
  showMeta: boolean;
  likeAmount: number;
  reviewAmount: number;
  reviewRate: number;
};

type Badge = {
  name: string;
};
type OptionalInfo = Partial<
  MetaData & {
    brandName: string;
    onClickBrandName: MouseEventHandler<HTMLButtonElement>;
    discount: number;
    badges: Badge[];
    soldOut?: boolean;
  }
>;

export interface ProductInfoProps extends RequiredInfo, ProductCardInfoVariants, OptionalInfo {}

const ProductCardInfo = ({
  discount,
  price,
  brandName,
  onClickBrandName,
  size = 'small',
  productName,
  likeAmount,
  reviewAmount,
  badges,
  hasPadding,
  productNameLineClamp = 1,
  soldOut = false,
  showMeta = true,
  reviewRate,
}: ProductInfoProps) => {
  const info = productCardInfo({
    size,
    hasPadding,
    productNameLineClamp,
    soldOut,
  });

  const handleClickBrandName = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClickBrandName?.(e);
  };

  return (
    <div className={info.base()}>
      <div className={info.header()}>
        <div className={info.requiredInfo()}>
          {/* TODO:(신다혜) TextButton 컴포넌트 제작 후 교체가 필요합니다. */}
          <button className={info.brandName()} onClick={handleClickBrandName}>
            {brandName && <p>{brandName}</p>}
            {onClickBrandName && <ChevronRightBoldIcon size={12} />}
          </button>
          <p className={info.productName()}>{productName}</p>
        </div>
        <div className={info.priceInfo()}>
          {discount ? <p className={info.discount()}>{discount}%</p> : null}
          <p>{commaizeNumber(price)}</p>
        </div>
      </div>
      <div className={info.metaDataGroup()}>
        <BadgeGroup badges={badges} soldOut={soldOut} />
        {showMeta && (
          <AmountMetaData likeAmount={likeAmount} reviewAmount={reviewAmount} reviewRate={reviewRate} size={size} />
        )}
      </div>
    </div>
  );
};

const BadgeGroup = ({ badges, soldOut }: { badges?: Badge[]; soldOut?: boolean }) => {
  const info = productCardInfo();

  if (soldOut) {
    return (
      <div className={info.metaData()}>
        <Badge size="small" priority="tertiary">
          임시품절
        </Badge>
      </div>
    );
  }

  if (!badges || badges.length === 0) {
    return null;
  }

  return (
    <div className={info.metaData()}>
      {badges.map(({ name }) => (
        <Badge key={name} size="small" priority="secondary">
          {name}
        </Badge>
      ))}
    </div>
  );
};

const AmountMetaData = ({
  likeAmount = 0,
  reviewAmount = 0,
  reviewRate = 0,
  size,
}: Partial<MetaData> & ProductCardInfoVariants) => {
  const info = productCardInfo({
    size,
  });
  return (
    <div className={info.metaData()}>
      <div className="flex items-center gap-2">
        <HeartBoldFillIcon size={12} />
        <span className={info.amountText()}>{commaizeNumber(likeAmount)}</span>
      </div>
      <div className="flex items-center gap-2">
        <StarBoldFillIcon size={12} />
        <span className={info.amountText()}>
          {reviewRate} ({commaizeNumber(reviewAmount)})
        </span>
      </div>
    </div>
  );
};

export default ProductCardInfo;
