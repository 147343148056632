import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcEyeOffBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 9.865c-.569 0-1.1.22-1.503.623a2.1 2.1 0 0 0-.622 1.503c0 .57.22 1.1.623 1.504l-1.416 1.413a4.1 4.1 0 0 1-1.207-2.917c0-1.1.43-2.14 1.208-2.917A4.1 4.1 0 0 1 12 7.865c1.1 0 2.139.43 2.917 1.21l-1.414 1.413A2.1 2.1 0 0 0 12 9.865"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.311 18.987 19.378 3.92l1.414 1.414L5.725 20.401z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.48 16.638c-2.238-.702-4.259-2.324-5.635-4.647C5.725 8.82 8.806 6.953 12 6.953c1.888 0 3.738.653 5.323 1.842l1.425-1.426C16.801 5.833 14.46 4.953 12 4.953c-4.195 0-8.046 2.56-10.184 6.568l-.252.47.252.471c1.416 2.654 3.584 4.673 6.105 5.734zm2.438.39-1.823 1.823q.936.176 1.905.177c4.195 0 8.045-2.559 10.184-6.566l.252-.47-.252-.471a13.5 13.5 0 0 0-1.97-2.79L18.8 10.146c.499.552.954 1.169 1.355 1.845-1.88 3.172-4.961 5.037-8.155 5.037z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcEyeOffBold;
