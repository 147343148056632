import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcBagLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.828 3.747A4.62 4.62 0 0 1 12 2.5c1.185 0 2.327.446 3.172 1.247a4.2 4.2 0 0 1 1.297 2.542h3.329a.7.7 0 0 1 .702.69v13.832c0 .402-.332.689-.702.689H4.195a.7.7 0 0 1-.476-.192.7.7 0 0 1-.219-.485V6.98c0-.402.332-.69.702-.69h3.329a4.2 4.2 0 0 1 1.297-2.542M8.54 6.289h6.918a3.22 3.22 0 0 0-.974-1.816A3.62 3.62 0 0 0 12 3.5c-.937 0-1.83.353-2.485.973a3.22 3.22 0 0 0-.974 1.816m-4.041 1V20.5h15v-6.105H12v-1h7.5V7.289z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcBagLight;
