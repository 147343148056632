/* eslint-disable prefer-spread */
/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { keys } from '@29cm/utils-objects';
import { createAnalytics } from './createAnalytics';

type WindowWithFacebookPixel = Window & {
  fbq?: FacebookPixelFunction;
  _fbq?: any;
};

type FacebookPixelFunction = (type: string, name: string, properties?: Record<PropertyKey, unknown>) => void;

type FacebookPixelTrackingServiceType = 'GOOGLE' | 'NAVER';

type FacebookPixelTrackingService = {
  url: string;
  query: string;
};

type FacebookPixelTrackingServices = Record<FacebookPixelTrackingServiceType, FacebookPixelTrackingService>;

// FIXME: 환경변수로 관리
const FACEBOOK_PIXEL_ID = '1470671216481125';

export const facebookPixel = createAnalytics({
  platform: 'facebookPixel',
  initializer: async () => {
    // FIXME: Webview 에서 실행되지 않도록 처리
    const instance = await loadFacebookPixelScript();

    if (instance === undefined) {
      return;
    }

    instance('init', FACEBOOK_PIXEL_ID);
    instance('track', 'PageView');

    const searchKeywords = retrieveSearchKeywords();

    // 네이버, 구글에서 검색하여 29cm 서비스로 접속한 경우, 해당 referrer 에서 검색한 키워드를 Facebook Pixel 로 push 합니다.
    if (searchKeywords !== undefined) {
      instance('trackCustom', 'SearchKeywords', { keyword: searchKeywords });
    }

    return instance;
  },
  tracker: (instance, name, properties) => {
    instance?.('track', name, properties);
  },
});

const loadFacebookPixelScript = (): Promise<FacebookPixelFunction | undefined> => {
  return new Promise<FacebookPixelFunction | undefined>((resolve) => {
    /**
     * {@link https://developers.facebook.com/docs/meta-pixel/get-started/ Facebook-Pixel Docs}
     */
    (function (f, b, e, v, n?: any, t?: any, s?: any) {
      if (f.fbq !== undefined) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      t.onload = function () {
        return resolve((window as WindowWithFacebookPixel).fbq);
      };
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window as WindowWithFacebookPixel, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  });
};

const TRACKING_SERVICES: Readonly<FacebookPixelTrackingServices> = {
  GOOGLE: {
    url: 'google.com/search?',
    query: 'q',
  },
  NAVER: {
    url: 'search.naver.com/search.naver?',
    query: 'query',
  },
};

const retrieveSearchKeywords = (): string | undefined => {
  const { referrer } = document;

  // TRACKING_SERVICES 를 기준으로 referrer url 이 해당하는 service 객체를 가져옵니다.
  const service = getMatchedService(referrer);

  if (service === undefined) {
    return;
  }

  // referrer url 로부터 query parameter 들을 객체 형태로 가져옵니다.
  const queries = getQueries(referrer);

  // 객체 query parameter 들 중 대상 service 객체의 query 프로퍼티에 해당하는 값을 반환합니다.
  return queries[service.query];
};

const getMatchedService = (referrer: string): FacebookPixelTrackingService | undefined => {
  const serviceKeys = keys(TRACKING_SERVICES).filter(
    (service) => referrer.indexOf(TRACKING_SERVICES[service].url) > -1,
  );

  const serviceKey = serviceKeys.length > 0 ? serviceKeys[0] : undefined;

  return serviceKey !== undefined ? TRACKING_SERVICES[serviceKey] : undefined;
};

const getQueries = (referrer: string): Record<string, string> => {
  const hashes = referrer.slice(referrer.indexOf('?') + 1).split('&');

  return hashes.reduce(
    (prev, curr) => {
      const [key, value] = curr.split('=');

      if (!key || !value) {
        return prev;
      }

      return { ...prev, [key]: value };
    },
    {} as Record<string, string>,
  );
};
