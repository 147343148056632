'use client';

import { transparentFallbackImageSrc } from '@29cm/contexts-common-constants';
import { Intersection } from '@29cm/contexts-common-features';
import { ResponsiveImage } from '@29cm/contexts-common-views';
import { trackClickSideHomeBannerEvent, trackViewSideHomeBannerEvent } from '@29cm/contexts-events/services';
import { Feed } from '@29cm/contexts-feeds-services';
import { memo } from 'react';

interface Props {
  feed: Feed;
}

export const BannerFeedLayout = memo(({ feed }: Props) => {
  const { relatedFiles, title } = feed;

  const [thumbnail] = relatedFiles;

  const handleView = () => trackViewSideHomeBannerEvent(feed);

  const handleClick = () => trackClickSideHomeBannerEvent(feed);

  return (
    <Intersection onView={handleView}>
      <div
        className="relative bg-low"
        style={{
          aspectRatio: thumbnail ? `${thumbnail.width}/${thumbnail.height}` : undefined,
        }}
        onClick={handleClick}
      >
        <ResponsiveImage
          src={thumbnail?.uploadName ?? transparentFallbackImageSrc}
          alt={title}
          sizes={{ sm: '100vw', md: '40vw' }}
          fill
          priority
        />
      </div>
    </Intersection>
  );
});
