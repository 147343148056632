import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcSettingBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M13.637 22h-3.25c-.28 0-.55-.09-.78-.25-.23-.17-.4-.39-.5-.66l-.56-1.601a8.2 8.2 0 0 1-1.53-.85l-1.77.35c-.27.05-.55.03-.81-.09-.27-.12-.48-.31-.62-.55l-1.61-2.662c-.15-.25-.22-.55-.19-.85.03-.29.15-.56.35-.781l1.15-1.25a7.5 7.5 0 0 1 0-1.641l-1.15-1.231c-.2-.21-.32-.49-.36-.78-.03-.29.04-.6.19-.85l1.62-2.672c.14-.24.35-.43.61-.54.26-.12.54-.15.81-.09l1.77.35c.48-.33.99-.62 1.53-.85l.55-1.592c.09-.26.26-.49.49-.66.24-.17.51-.25.79-.25h3.25c.28 0 .56.09.79.25.23.17.4.4.5.66l.56 1.591c.54.23 1.05.51 1.53.85l1.77-.35q.42-.075.81.09c.26.11.47.3.62.54l1.61 2.672c.15.25.22.55.19.84-.03.3-.16.58-.36.791l-1.15 1.24a7.5 7.5 0 0 1 0 1.641l1.15 1.241c.2.22.33.49.36.79.03.29-.04.58-.19.83l-1.61 2.672c-.14.24-.36.43-.61.54-.27.12-.55.14-.82.09l-1.76-.34c-.48.33-.99.62-1.53.85l-.55 1.592c-.09.27-.26.5-.5.66-.22.16-.5.25-.79.25zm-2.8-2.001h2.34l.71-2.051.45-.16a6.7 6.7 0 0 0 1.81-1l.36-.28 2.21.43 1.13-1.871-1.48-1.601.08-.48c.11-.65.11-1.321 0-1.972l-.08-.48 1.48-1.6-1.13-1.871-2.21.43-.36-.28a6.7 6.7 0 0 0-1.81-1l-.45-.161-.71-2.041h-2.34l-.71 2.051-.45.16c-.65.24-1.26.57-1.81 1l-.36.28-2.21-.43-1.13 1.871 1.48 1.601-.08.48c-.11.65-.11 1.321 0 1.972l.08.49-1.48 1.6 1.13 1.871 2.21-.43.36.28c.55.43 1.16.76 1.81 1l.45.161.71 2.041zm3.97-7.003h-5.6v-2.001h5.6z"
      />
    </svg>
  );
});
export default SvgIcSettingBold;
