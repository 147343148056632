import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcLockoffBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8 10h11.25a.75.75 0 0 1 .75.75v10.5a.75.75 0 0 1-.75.75H4.75a.75.75 0 0 1-.75-.75v-10.5a.75.75 0 0 1 .75-.75H6V8a6 6 0 0 1 10.86-3.52l-1.553 1.269A4 4 0 0 0 8 8zm4 2.5a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcLockoffBoldFill;
