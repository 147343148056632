'use client';

import { transparentFallbackImageSrc } from '@29cm/contexts-common-constants';
import { getCampaignLink, getImageUrl, isMdScreen } from '@29cm/contexts-common-utils';
import { Link } from '@29cm/contexts-common-views';
import { trackClickFeedProductEvent } from '@29cm/contexts-events/services';
import { Feed, FeedProduct } from '@29cm/contexts-feeds-services';
import { ProductHorizontalCardWithHeart } from '@29cm/contexts-products-features';
interface Props {
  product: FeedProduct;
  feed: Feed;
}

export const ContentsFeedItem = ({ product, feed }: Props) => {
  const { images, frontBrand, name, sale, heartCount, id } = product;
  const [image] = images;

  const imageUrl = getImageUrl(image?.uploadName ?? transparentFallbackImageSrc);
  const href = getCampaignLink('product', id);

  const handleClick = () => {
    // NOTE: 모바일에서만 해당 이벤트를 수집합니다.
    if (isMdScreen()) {
      return;
    }

    trackClickFeedProductEvent({ feed, product });
  };

  return (
    <li className="border-t border-solid border-line pt-10">
      <Link href={href} onClick={handleClick}>
        <ProductHorizontalCardWithHeart
          productId={id}
          imgUrl={imageUrl}
          productName={name}
          price={sale.totalSellPrice}
          discount={sale.totalSaleRate}
          brandName={frontBrand.nameKor}
          likeAmount={heartCount}
        />
      </Link>
    </li>
  );
};
