import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcCouponBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4 8.634A3.89 3.89 0 0 1 5.905 12c0 1.475-.781 2.677-1.905 3.342V18h16v-2.634A3.9 3.9 0 0 1 18.095 12c0-1.475.781-2.677 1.905-3.341V6H4zm17.762 1.484c.143 0 .238-.095.238-.236V4.235c0-.14-.095-.235-.238-.235H2.238C2.095 4 2 4.094 2 4.235v5.647c0 .142.095.236.238.236.952.094 1.667.894 1.667 1.882s-.715 1.741-1.667 1.882c-.143 0-.238.095-.238.236v5.647c0 .14.095.235.238.235h19.524c.143 0 .238-.094.238-.235v-5.647c0-.142-.095-.236-.238-.236-.953-.094-1.667-.894-1.667-1.882s.715-1.741 1.667-1.882"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcCouponBold;
