import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcBelloffLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.48 5.932a6.3 6.3 0 0 0-1.135-1.58A6.18 6.18 0 0 0 11.94 2.5a6.18 6.18 0 0 0-4.405 1.852 6.36 6.36 0 0 0-1.821 4.461v4.684l-1.568 3.266h1.72L3.7 18.783l.682.731L20.472 4.51l-.682-.731zM11.94 3.5a5.18 5.18 0 0 0-3.694 1.554 5.36 5.36 0 0 0-1.533 3.76v4.91l-.979 2.04h1.204l9.775-9.116a5.3 5.3 0 0 0-1.08-1.594A5.18 5.18 0 0 0 11.939 3.5"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="m8.797 16.763 1.073-1h8.275l-.979-2.039V8.96l.957-.892q.043.37.043.746v4.684l1.568 3.266zM11.94 21.5c-2.013 0-3.767-1.433-3.767-3.342h1c0 1.23 1.17 2.342 2.766 2.342s2.767-1.112 2.767-2.342h1c0 1.909-1.754 3.342-3.767 3.342"
      />
    </svg>
  );
});
export default SvgIcBelloffLight;
