import { firebase } from '@29cm/contexts-analytics/services';
import { createEvent } from './createEvent';

export const trackVisitHomeMainPageEvent = createEvent({
  name: 'visit_home_main_page',
  trackers: [firebase.track],
});

export const trackClickCouponTriggerEvent = createEvent({
  name: 'click_coupon_trigger',
  trackers: [firebase.track({ curr_screen: 'home_main' })],
});

export const trackViewCouponTriggerEvent = createEvent({
  name: 'view_coupon_trigger',
  trackers: [firebase.track({ curr_screen: 'home_main' })],
});
