import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcBellBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 4c-1.249 0-2.45.503-3.337 1.404a4.86 4.86 0 0 0-1.39 3.41v5.024l-.684 1.425h10.822l-.684-1.425V8.813a4.86 4.86 0 0 0-1.39-3.409A4.68 4.68 0 0 0 12 4M7.238 4C8.498 2.722 10.211 2 12 2s3.502.721 4.762 2a6.86 6.86 0 0 1 1.965 4.813v4.57l1.862 3.88H3.411l1.862-3.88v-4.57c0-1.802.705-3.533 1.965-4.812M12 22c-2.221 0-4.267-1.594-4.267-3.842h2c0 .891.88 1.842 2.267 1.842s2.267-.95 2.267-1.842h2C16.267 20.406 14.22 22 12 22"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcBellBold;
