import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcDeliveryRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.2 3a1.3 1.3 0 0 1 1.3-1.3H22v1.6h-6.2v15.9H22v1.6h-9.498A3.5 3.5 0 0 1 9.5 22.5a3.5 3.5 0 0 1-3.002-1.7H3.5a1.3 1.3 0 0 1-1.3-1.3v-5.653a1.3 1.3 0 0 1 .843-1.218l3.366-1.262 2.23-4.905A1.3 1.3 0 0 1 9.822 5.7H14.2zM8.031 20.206A1.9 1.9 0 0 1 7.6 19a1.9 1.9 0 1 1 .431 1.206M6.006 19.2H3.8v-5.146L7.145 12.8H14.2v6.4h-1.206Q13 19.1 13 19a3.5 3.5 0 1 0-6.994.2M14.2 7.3h-4.185l-1.773 3.9H14.2z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcDeliveryRegular;
