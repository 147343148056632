import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcPencilRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.87 5.046a3.8 3.8 0 0 0-5.373 0l-7.778 7.778a.8.8 0 0 0-.227.453l-.66 4.62a1.05 1.05 0 0 0 1.188 1.187l4.62-.66a.8.8 0 0 0 .453-.226l7.778-7.778a3.8 3.8 0 0 0 0-5.374m-4.242 1.131a2.2 2.2 0 0 1 3.111 3.112l-.707.707-3.111-3.112zM12.79 8.016l-5.752 5.75-.518 3.63 3.63-.518 5.75-5.751z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcPencilRegular;
