import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcArrowLeftLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.654 4.396a.5.5 0 0 1 0 .708L4.006 11.75H21.3a.5.5 0 0 1 0 1H4.069l6.572 6.134a.5.5 0 1 1-.682.732l-7.5-7a.5.5 0 0 1-.013-.72l7.5-7.5a.5.5 0 0 1 .707 0"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcArrowLeftLight;
