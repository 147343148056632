import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcLockoffRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path fill="currentColor" d="M12 12.7a.8.8 0 0 1 .8.8v2a.8.8 0 0 1-1.6 0v-2a.8.8 0 0 1 .8-.8" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M19.25 10a.75.75 0 0 1 .75.75v10.5a.75.75 0 0 1-.75.75H4.75a.75.75 0 0 1-.75-.75v-10.5a.75.75 0 0 1 .75-.75H6.2V8a5.8 5.8 0 0 1 10.504-3.393l-1.242 1.015A4.2 4.2 0 0 0 7.8 8v2zM5.6 20.4v-8.8h12.8v8.8z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcLockoffRegular;
