import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcSearchListLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.659 4.5a6.159 6.159 0 1 0 3.98 10.859l3.008 2.995.706-.708-3.006-2.993A6.159 6.159 0 0 0 14.66 4.5M9.5 10.659a5.159 5.159 0 1 1 10.318 0 5.159 5.159 0 0 1-10.318 0"
        clipRule="evenodd"
      />
      <path fill="currentColor" d="M2 5.5v1h5.5v-1zM2 17.5v1h8.5v-1zM2 12.5v-1h5.5v1z" />
    </svg>
  );
});
export default SvgIcSearchListLight;
