import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcBellRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 3.8a4.88 4.88 0 0 0-3.48 1.464 5.06 5.06 0 0 0-1.447 3.55v4.979l-.802 1.67H17.73l-.802-1.67v-4.98c0-1.333-.522-2.61-1.447-3.549A4.89 4.89 0 0 0 12 3.8m-4.62.341A6.48 6.48 0 0 1 12 2.2c1.735 0 3.396.7 4.62 1.941a6.66 6.66 0 0 1 1.907 4.672v4.615l1.744 3.635H3.73l1.744-3.634V8.813c0-1.75.685-3.43 1.908-4.672M12 21.8c-2.138 0-4.067-1.53-4.067-3.642h1.6c0 1.027.997 2.042 2.467 2.042s2.467-1.015 2.467-2.042h1.6c0 2.112-1.93 3.642-4.067 3.642"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcBellRegular;
