import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcBellLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 3.5a5.18 5.18 0 0 0-3.693 1.554 5.36 5.36 0 0 0-1.534 3.76v4.91l-.978 2.04h12.41l-.978-2.04v-4.91a5.36 5.36 0 0 0-1.534-3.76A5.18 5.18 0 0 0 12 3.5m-4.406.852A6.18 6.18 0 0 1 12 2.5c1.654 0 3.239.667 4.406 1.852a6.36 6.36 0 0 1 1.82 4.461v4.684l1.569 3.266H4.205l1.568-3.266V8.813c0-1.672.654-3.277 1.821-4.461M12 21.5c-2.013 0-3.767-1.433-3.767-3.342h1c0 1.23 1.171 2.342 2.767 2.342s2.767-1.112 2.767-2.342h1c0 1.909-1.754 3.342-3.767 3.342"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcBellLight;
