import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcRemoveBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10M7.456 8.588l1.131-1.132 3.412 3.407 3.396-3.392 1.13 1.132-3.394 3.39 3.395 3.39-1.13 1.132-3.397-3.391-3.412 3.407-1.13-1.132 3.41-3.406z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcRemoveBoldFill;
