import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcEyeOffRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 9.665c-.622 0-1.203.24-1.644.682-.44.44-.681 1.021-.681 1.644s.24 1.204.681 1.645l-1.132 1.13a3.9 3.9 0 0 1-1.149-2.775c0-1.047.41-2.035 1.15-2.775.74-.741 1.729-1.15 2.775-1.15s2.035.409 2.776 1.15l-1.132 1.13A2.3 2.3 0 0 0 12 9.667"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.452 19.128 19.52 4.061l1.132 1.132L5.584 20.26z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.096 16.72c-2.19-.78-4.148-2.427-5.483-4.729C5.517 8.705 8.691 6.753 12 6.753c1.877 0 3.71.628 5.293 1.771l1.143-1.143C16.56 5.96 14.332 5.153 12 5.153c-4.11 0-7.898 2.509-10.008 6.462l-.2.376.2.377c1.372 2.57 3.453 4.53 5.872 5.585zm1.825.44-1.37 1.368c.796.197 1.616.3 2.45.3 4.11 0 7.897-2.508 10.007-6.46l.2-.377-.2-.376a13.2 13.2 0 0 0-2.36-3.184l-1.13 1.131c.705.697 1.337 1.51 1.869 2.43-1.904 3.284-5.078 5.236-8.387 5.236q-.543 0-1.08-.069"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcEyeOffRegular;
