import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcHomeLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.25 3.5a.75.75 0 0 0-.75.75v7.25h8v-8H4.25m.25 7v-6h6v6zm-.5 2h-.5v7.25c0 .414.336.75.75.75h7.25v-8H4m.5 7v-6h6v6zm8-16h7.25a.75.75 0 0 1 .75.75v7.25h-8v-8m1 1v6h6v-6zm-.5 8h-.5v8h7.25a.75.75 0 0 0 .75-.75V12.5H13m.5 7v-6h6v6z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcHomeLight;
