'use client';

import { Responsive } from '@29cm/contexts-common-views';
import { Divider } from '@29cm/contexts-home/views';
import { Button } from '@29cm/ruler';

interface Props {
  className?: string;
  onClick?: () => void;
}

export const ReadMoreSection = ({ className, onClick }: Props) => {
  return (
    <div className={className}>
      <div className="relative">
        <Gradation />
        <ResponsiveGridLine />

        <div className="flex flex-col items-center gap-30 bg-on-white pb-30 pt-30 md:pt-0">
          <Responsive className="w-full" md={<Divider direction="horizontal" />} />

          <Button
            className="h-[52px] w-[160px] min-w-[88px]"
            priority="secondary"
            size="large"
            type="button"
            actionIcon="chevronDown"
            round
            onClick={onClick}
          >
            더보기
          </Button>
        </div>
      </div>
    </div>
  );
};

const Gradation = () => {
  return (
    <>
      <div className="pointer-events-none absolute inset-x-0 bottom-[100%] h-180 bg-on-white [background:linear-gradient(180deg,rgba(255,255,255,0)_0%,rgb(255,255,255)_92.5%)]" />
      <div className="absolute bottom-[100%] left-0 right-0 h-30" />
    </>
  );
};

const ResponsiveGridLine = () => {
  return (
    <>
      <Responsive md={<Divider className="absolute -top-180 left-[44%] h-180" direction="vertical" />} />
      <Responsive md={<Divider className="absolute -top-180 right-[28%] h-180" direction="vertical" />} />
    </>
  );
};
