import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcHouseRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.309 2.542a1.05 1.05 0 0 1 1.382 0l7.75 6.781c.228.2.359.488.359.79V20.75c0 .58-.47 1.05-1.05 1.05H4.25c-.58 0-1.05-.47-1.05-1.05V10.113c0-.302.13-.59.359-.79zM12 4.063l-7.2 6.3V20.2h6.4V13h1.6v7.2h6.4v-9.837z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcHouseRegular;
