import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcGiftRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.585 4.114c.49.387.97.82 1.415 1.253.445-.432.926-.866 1.415-1.253.695-.548 1.467-1.05 2.216-1.28.773-.237 1.69-.22 2.358.545.465.532.704 1.322.782 2.32h1.979c.58 0 1.05.47 1.05 1.05v2.5c0 .58-.47 1.05-1.05 1.05h-.95v9.95c0 .58-.47 1.05-1.05 1.05H5.25c-.58 0-1.05-.47-1.05-1.05v-9.95h-.95c-.58 0-1.05-.47-1.05-1.05v-2.5c0-.58.47-1.05 1.05-1.05h1.98c.077-.998.316-1.788.78-2.32.67-.764 1.586-.782 2.36-.545.748.23 1.52.732 2.215 1.28M20.2 7.3v1.4h-7.4v-1.4zm-9 0v1.4H3.8v-1.4zm0 12.4v-9.4H5.8v9.4zm1.6-9.4h5.4v9.4h-5.4zm1.207-4.6q.2-.171.4-.329c.646-.51 1.23-.864 1.693-1.006.439-.135.59-.04.685.069.138.157.308.523.38 1.266zm-4.014 0a14 14 0 0 0-.4-.329c-.646-.51-1.23-.864-1.693-1.006-.439-.135-.59-.04-.685.069-.138.157-.308.523-.38 1.266z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcGiftRegular;
