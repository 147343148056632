import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcSpaceBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <g className="ic-29space-bold-fill">
        <g fill="currentColor" className="vector">
          <path d="M14.778 14.125a.773.773 0 1 1 0-1.546.773.773 0 0 1 0 1.546" />
          <path
            fillRule="evenodd"
            d="M12.802 3.297a1.19 1.19 0 0 0-1.573 0l-8.326 7.34a1.2 1.2 0 0 0-.403.892v8.282A1.19 1.19 0 0 0 3.69 21h16.65a1.19 1.19 0 0 0 1.19-1.19v-8.28a1.2 1.2 0 0 0-.403-.893zm1.412 12.198c-.992-.226-1.743-1.092-1.743-2.143 0-1.253 1.045-2.248 2.307-2.248s2.308.995 2.308 2.248c0 .431-.131.853-.377 1.208l-2.085 3.014h-1.882zm-4.189-2.298c0 .142-.054.28-.153.382l-2.614 2.749v1.247h4.302v-1.476H9.557l1.454-1.528c.352-.37.549-.863.549-1.374 0-1.168-.975-2.093-2.151-2.093s-2.151.925-2.151 2.093v.38h1.534v-.38a.617.617 0 1 1 1.233 0"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
});
export default SvgIcSpaceBoldFill;
