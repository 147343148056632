import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcCheckCalendarBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.524 3.986V2.102h-2v1.884H4.5c-.827 0-1.5.673-1.5 1.5v13.912c0 .827.673 1.5 1.5 1.5h15c.827 0 1.5-.673 1.5-1.5V5.486c0-.827-.673-1.5-1.5-1.5h-2.146V2.102h-2v1.884zM14.938 9l-3.724 4.545-2.123-2.858-1.605 1.193 2.886 3.886a1 1 0 0 0 1.577.038l4.536-5.536z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcCheckCalendarBoldFill;
