import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcRedoBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M11.75 4.585V3l3.753 2.75L11.75 8.5V6.586a6.25 6.25 0 1 0 5.538 2.912L18.98 8.43a8.25 8.25 0 1 1-7.23-3.846"
      />
    </svg>
  );
});
export default SvgIcRedoBoldFill;
