import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcSearchBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.9 4.5a6.4 6.4 0 1 0 0 12.8 6.4 6.4 0 0 0 0-12.8m-8.4 6.4a8.4 8.4 0 1 1 14.998 5.2l3.708 3.691-1.412 1.418-3.712-3.697A8.4 8.4 0 0 1 2.5 10.9"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcSearchBold;
