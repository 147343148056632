import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcHomeRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.7 4.75c0-.58.47-1.05 1.05-1.05h7.05v8.1H3.7zm1.6.55v4.9h4.9V5.3zm7.4-1.6h7.05c.58 0 1.05.47 1.05 1.05v7.05h-8.1zm1.6 1.6v4.9h4.9V5.3zM3.7 12.7h8.1v8.1H4.75c-.58 0-1.05-.47-1.05-1.05zm1.6 1.6v4.9h4.9v-4.9zm7.4-1.6h8.1v7.05c0 .58-.47 1.05-1.05 1.05H12.7zm1.6 1.6v4.9h4.9v-4.9z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcHomeRegular;
