import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcTrashRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M20.8 3.27c.11 0 .2.09.2.2v1.2a.2.2 0 0 1-.2.2H3.2a.2.2 0 0 1-.2-.2v-1.2c0-.11.09-.2.2-.2h7.982v-1c0-.11.09-.2.2-.2h1.2c.11 0 .2.09.2.2v1zM18.69 6.21l-.978 14.115H6.28L5.306 6.21a.15.15 0 0 0-.15-.14H3.861a.15.15 0 0 0-.15.16l1.067 15.556a.15.15 0 0 0 .15.14h14.137a.15.15 0 0 0 .15-.14L20.298 6.23a.15.15 0 0 0-.15-.16L18.84 6.07a.15.15 0 0 0-.15.14"
      />
      <path
        fill="currentColor"
        d="M10.65 6.07a.15.15 0 0 1 .15.15v10.7a.15.15 0 0 1-.15.15h-1.3a.15.15 0 0 1-.15-.15V6.22a.15.15 0 0 1 .15-.15zM14.65 6.07a.15.15 0 0 1 .15.15v10.7a.15.15 0 0 1-.15.15h-1.3a.15.15 0 0 1-.15-.15V6.22a.15.15 0 0 1 .15-.15z"
      />
    </svg>
  );
});
export default SvgIcTrashRegular;
