import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcDocumentRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.2 4.25c0-.58.47-1.05 1.05-1.05h15.5c.58 0 1.05.47 1.05 1.05v15.5c0 .58-.47 1.05-1.05 1.05H4.25c-.58 0-1.05-.47-1.05-1.05zm1.6.55v14.4h14.4V4.8z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.7 7.2h8.6v1.6H7.7zM7.7 11.2h8.6v1.6H7.7zM7.7 15.2h8.6v1.6H7.7z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcDocumentRegular;
