import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcFilterDotBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.84 7.492h-3.18v-2h3.18zM12.676 7.492H5.16v-2h7.516zM18.84 13H5.16v-2h13.68z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.168 6a.492.492 0 1 0 0 .984.492.492 0 0 0 0-.984m-2.492.492a2.492 2.492 0 1 1 4.984 0 2.492 2.492 0 0 1-4.984 0M5.16 16.508h3.18v2H5.16zM11.324 16.508h7.516v2h-7.516z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.832 17.016a.492.492 0 1 0 0 .984.492.492 0 0 0 0-.984m-2.492.492a2.492 2.492 0 1 1 4.984 0 2.492 2.492 0 0 1-4.984 0"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcFilterDotBoldFill;
