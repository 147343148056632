import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcSettingLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M13.64 21.5h-3.25c-.18 0-.351-.05-.501-.16a.77.77 0 0 1-.31-.42l-.63-1.811a8.2 8.2 0 0 1-1.812-1l-1.98.39c-.17.03-.35.01-.51-.06a.83.83 0 0 1-.391-.35l-1.63-2.662a.85.85 0 0 1-.121-.54c.02-.19.1-.36.22-.49l1.31-1.412q-.135-.99 0-1.98l-1.3-1.411a.9.9 0 0 1-.23-.49c-.02-.19.02-.38.12-.54l1.61-2.672c.091-.15.221-.27.391-.34.16-.07.34-.09.52-.06l1.981.39c.56-.41 1.171-.75 1.811-1l.63-1.802c.06-.17.17-.31.31-.42.151-.11.321-.16.501-.16h3.252a.9.9 0 0 1 .5.16c.15.11.26.25.31.42l.63 1.801c.651.26 1.251.59 1.812 1l1.98-.39c.17-.03.35-.01.51.06s.301.19.391.34l1.61 2.672c.101.16.141.35.121.53-.02.19-.1.36-.23.5l-1.31 1.412q.135.99 0 1.98l1.31 1.411c.13.14.21.31.22.5a.82.82 0 0 1-.12.53l-1.61 2.672c-.091.15-.221.27-.391.34s-.34.09-.52.06l-1.981-.39c-.56.41-1.171.75-1.811 1l-.63 1.812c-.06.17-.17.31-.31.42-.141.1-.321.16-.501.16zm-3.15-1h3.05l.751-2.162.22-.08c.7-.25 1.361-.62 1.951-1.08l.18-.14 2.332.45 1.51-2.491-1.57-1.691.04-.24c.12-.71.12-1.431 0-2.142l-.04-.24 1.57-1.69-1.51-2.502-2.332.46-.18-.14c-.59-.46-1.25-.82-1.95-1.08l-.22-.08-.751-2.161h-3.052l-.75 2.16-.22.08c-.7.25-1.361.621-1.951 1.081l-.18.14-2.332-.46-1.51 2.501 1.57 1.691-.04.24c-.12.71-.12 1.431 0 2.142l.04.24-1.57 1.69 1.51 2.492 2.332-.45.18.14q.885.69 1.95 1.08l.22.08zm3.821-8.005H9.71v-1h4.602z"
      />
    </svg>
  );
});
export default SvgIcSettingLight;
