import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcBagBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.484 3.384A5.12 5.12 0 0 1 12 2c1.31 0 2.575.492 3.516 1.384a4.74 4.74 0 0 1 1.374 2.405h2.908c.622 0 1.202.498 1.202 1.19v13.832c0 .7-.579 1.189-1.202 1.189H4.188a1.2 1.2 0 0 1-.811-.327A1.2 1.2 0 0 1 3 20.836V6.98c0-.7.579-1.19 1.202-1.19H7.11c.203-.91.68-1.747 1.374-2.405M9.2 5.789h5.6a2.8 2.8 0 0 0-.66-.953A3.12 3.12 0 0 0 12 4c-.813 0-1.582.306-2.14.836a2.8 2.8 0 0 0-.66.953m-4.2 2V20h14v-5.105h-7v-2h7V7.789z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcBagBold;
