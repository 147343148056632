'use client';

import { Intersection } from '@29cm/contexts-common-features';
import { useFetchInfinite } from '@29cm/contexts-common-hooks';
import { Feeds, HomeType, fetchFeeds } from '@29cm/contexts-feeds-services';
import { mobileFeedSize } from '@29cm/contexts-home/constants';
import { Result } from '@29cm/contexts-http/interfaces';
import { flatResults } from '@29cm/contexts-http/utils';
import { Feed } from '../Feed';

interface FeedListProps {
  className?: string;
  initialFeeds?: Result<Feeds, Error>;
}

export const FeedList = ({ className, initialFeeds }: FeedListProps) => {
  const { results: mobileFeedsResults, fetchNextPage: readMoreMobileFeeds } = useFetchInfinite(
    fetchFeeds,
    (pageParams?: { offset: number }) => ({
      offset: pageParams?.offset ?? 0,
      limit: mobileFeedSize,
      homeType: HomeType.M_WEB_HOME,
    }),
    {
      initialResult: initialFeeds,
      getNextPageParams: (incoming, existing) => {
        if (incoming.success && !incoming.data.next) {
          return;
        }

        return {
          offset: existing.length * mobileFeedSize,
        };
      },
    },
  );

  const feedList = flatResults(mobileFeedsResults, (data) => data.results);

  return (
    <ul className={className}>
      {feedList.map((data) => (
        <li key={data.id}>
          <Feed variant={data.type} item={data} />
        </li>
      ))}
      <Intersection onView={readMoreMobileFeeds} rootMargin="600px" />
    </ul>
  );
};
