import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcFilterDotLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.84 6.992h-3.18v-1h3.18zM12.676 6.992H5.16v-1h7.516zM18.84 12.5H5.16v-1h13.68z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.168 5.5a.992.992 0 1 0 0 1.984.992.992 0 0 0 0-1.984m-1.992.992a1.992 1.992 0 1 1 3.984 0 1.992 1.992 0 0 1-3.984 0M5.16 17.008h3.18v1H5.16zM11.324 17.008h7.516v1h-7.516z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.832 16.516a.992.992 0 1 0 0 1.984.992.992 0 0 0 0-1.984m-1.992.992a1.992 1.992 0 1 1 3.984 0 1.992 1.992 0 0 1-3.984 0"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcFilterDotLight;
