import { firebase } from '@29cm/contexts-analytics/services';
import { Feed, FeedProduct } from '@29cm/contexts-feeds-services';
import { createEvent } from './createEvent';

// TODO: 스키마 기반의 이벤트 타입 정의
const createFeedProperties = (feed: Feed) => ({
  feed_no: `${feed.id}`,
  feed_type: feed.type,
  feed_title: feed.title,
  feed_contents: feed.contents,
  link_type: feed.linkType,
  link_value: feed.linkValue,
});

// TODO: 스키마 기반의 이벤트 타입 정의
const createProductProperties = (product: FeedProduct) => ({
  item_no: `${product.id}`,
  item_name: product.name,
  like_count: `${product.heartCount}`,
  price: `${product.sale.sellPrice}`,
  review_count: `${product.reviewCount}`,
});

export const trackClickFeedEvent = createEvent<Feed>({
  name: 'click_feed',
  trackers: [
    firebase.track((feed) => ({
      ...createFeedProperties(feed),
      // NOTE: As-is 모바일 디바이스에서 position 을 항상 right 로 수집 (모바일은 list 가 세로 한 줄)
      position: 'right',
    })),
  ],
});

export const trackClickFeedProductEvent = createEvent<{ feed: Feed; product: FeedProduct }>({
  name: 'click_feed',
  trackers: [
    firebase.track(({ feed, product }) => ({
      ...createFeedProperties(feed),
      ...createProductProperties(product),
      // NOTE: As-is 모바일 디바이스에서 position 을 항상 right 로 수집 (모바일은 list 가 세로 한 줄)
      position: 'right',
    })),
  ],
});
