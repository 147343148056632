import { firebase } from '@29cm/contexts-analytics/services';
import { Banner } from '@29cm/contexts-banners-services';
import { Feed } from '@29cm/contexts-feeds-services';
import { createEvent } from './createEvent';

// TODO: 스키마 기반의 이벤트 타입 정의
const createBannerFeedProperties = (feed: Feed) => ({
  banner_no: `${feed.id}`,
  banner_title: feed.title,
  link_type: feed.linkType,
  link_value: feed.linkValue,
  position: feed.orderingIndex % 2 === 0 ? 'left' : 'right',
});

// TODO: 스키마 기반의 이벤트 타입 정의
const createBannerProperties = (banner: Banner) => ({
  banner_no: `${banner.bannerId}`,
  banner_title: banner.bannerTitle,
  banner_contents: banner.bannerContents,
  link_type: banner.linkType,
  link_value: banner.linkValue,
});

export const trackClickSideHomeBannerEvent = createEvent<Feed>({
  name: 'click_side_home_banner',
  trackers: [firebase.track(createBannerFeedProperties)],
});

export const trackViewSideHomeBannerEvent = createEvent<Feed>({
  name: 'view_side_home_banner',
  trackers: [firebase.track(createBannerFeedProperties)],
});

export const trackClickHomeBannerEvent = createEvent<{ banner: Banner; position: number }>({
  name: 'click_home_banner',
  trackers: [
    firebase.track(({ banner, position }) => ({
      ...createBannerProperties(banner),
      position: `${position}`,
    })),
  ],
});

export const trackViewHomeBannerEvent = createEvent<{ banner: Banner; position: number }>({
  name: 'view_home_banner',
  trackers: [
    firebase.track(({ banner, position }) => ({
      ...createBannerProperties(banner),
      position: `${position}`,
    })),
  ],
});
