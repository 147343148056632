import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcSearchListRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2 6.8V5.2h4.8v1.6zM2 18.8v-1.6h8.8v1.6zM2 12.8v-1.6h4.8v1.6zM14.659 5.8a4.859 4.859 0 1 0 0 9.718 4.859 4.859 0 0 0 0-9.718M8.2 10.659a6.459 6.459 0 1 1 11.551 3.973l2.813 2.801-1.128 1.134-2.817-2.805A6.459 6.459 0 0 1 8.2 10.659"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcSearchListRegular;
