import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcDownloadRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.2 13.704V3h1.6v10.704l3.665-3.299 1.07 1.19L12 16.576l-5.535-4.981 1.07-1.19zM18 19.7v1.6H6v-1.6z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcDownloadRegular;
