import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcTrashLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m17.993 20.775 1.002-14.442a.1.1 0 0 1 .1-.093h.798a.1.1 0 0 1 .1.107l-1.05 15.195a.25.25 0 0 1-.25.233H5.303a.25.25 0 0 1-.25-.233L4.007 6.347a.1.1 0 0 1 .1-.107h.798a.1.1 0 0 1 .1.093l.997 14.442z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.25 6.24a.25.25 0 0 1 .25.25v10.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25V6.49a.25.25 0 0 1 .25-.25zM14.25 6.24a.25.25 0 0 1 .25.25v10.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25V6.49a.25.25 0 0 1 .25-.25z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M3.2 4.74h17.6a.2.2 0 0 0 .2-.2v-.6a.2.2 0 0 0-.2-.2h-8.3V2.49a.25.25 0 0 0-.25-.25h-.5a.25.25 0 0 0-.25.25v1.25H3.2a.2.2 0 0 0-.2.2v.6c0 .11.09.2.2.2"
      />
    </svg>
  );
});
export default SvgIcTrashLight;
