import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcCouponLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3 9.269c1.132.396 1.905 1.479 1.905 2.731 0 1.293-.805 2.31-1.905 2.715V19h18v-4.269c-1.132-.396-1.905-1.479-1.905-2.731 0-1.293.805-2.31 1.905-2.715V5H3zM2.238 4h19.524c.143 0 .238.094.238.235v5.647c0 .142-.095.236-.238.236-.953.14-1.667.894-1.667 1.882s.715 1.788 1.667 1.882c.143 0 .238.095.238.236v5.647c0 .14-.095.235-.238.235H2.238C2.095 20 2 19.906 2 19.765v-5.647c0-.142.095-.236.238-.236.952-.14 1.667-.894 1.667-1.882s-.715-1.788-1.667-1.882c-.143 0-.238-.095-.238-.236V4.235c0-.14.095-.235.238-.235"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcCouponLight;
