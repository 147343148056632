import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcShareBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M16.5 2.5a3.5 3.5 0 0 0-3.34 4.547L8.523 9.576a3.5 3.5 0 1 0 0 4.849l4.635 2.528a3.5 3.5 0 1 0 .816-1.377l-4.634-2.529a3.5 3.5 0 0 0 0-2.094l4.635-2.528A3.5 3.5 0 1 0 16.5 2.5"
      />
    </svg>
  );
});
export default SvgIcShareBoldFill;
