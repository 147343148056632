import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcBelloffRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M20.228 17.063H8.471l1.716-1.6h7.497l-.802-1.67V9.22l1.523-1.42q.077.5.077 1.013v4.615z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m17.055 4.685 1.435-1.338 1.091 1.17-16.09 15.004-1.091-1.17 1.381-1.288h-.096l1.744-3.634V8.813c0-1.75.685-3.43 1.908-4.672a6.48 6.48 0 0 1 4.62-1.941 6.48 6.48 0 0 1 5.098 2.485M15.88 5.78A4.885 4.885 0 0 0 11.956 3.8a4.88 4.88 0 0 0-3.48 1.464 5.06 5.06 0 0 0-1.447 3.55v4.979l-.21.437z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M11.956 21.8c-2.138 0-4.066-1.53-4.066-3.642h1.6c0 1.027.996 2.042 2.466 2.042s2.467-1.015 2.467-2.042h1.6c0 2.112-1.93 3.642-4.067 3.642"
      />
    </svg>
  );
});
export default SvgIcBelloffRegular;
