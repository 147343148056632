import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcDeliveryBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M15.5 2h6a.5.5 0 0 1 .5.5v18a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5v-18a.5.5 0 0 1 .5-.5M13.75 8.5H10.5L9.708 11h4.042a.25.25 0 0 1 .25.25v4.35c0 .26-.388.371-.553.17a5.1 5.1 0 0 0-8.775 4.877c.057.17-.064.353-.243.353H3a1.5 1.5 0 0 1-1.5-1.5v-5.64a1.5 1.5 0 0 1 1.026-1.423l4.08-1.36q.09-.03.183-.048l1.652-4.542A1.5 1.5 0 0 1 9.85 5.5h3.9a.25.25 0 0 1 .25.25v2.5a.25.25 0 0 1-.25.25"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.5 19a4 4 0 1 1-8 0 4 4 0 0 1 8 0m-2.857 0a1.143 1.143 0 1 1-2.286 0 1.143 1.143 0 0 1 2.286 0"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcDeliveryBoldFill;
