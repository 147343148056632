import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcStarRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m9.995 10.037-4.675.713 3.389 3.475-.78 4.78 4.07-2.25 4.072 2.25-.78-4.78 3.39-3.475-4.676-.713L12 5.766zM8.91 8.584 2 9.639l5 5.125L5.82 22 12 18.583 18.18 22 17 14.764l5-5.125-6.91-1.055L12 2z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcStarRegular;
