import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcPencilLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.659 5.475a3.5 3.5 0 0 0-4.95 0L5.93 13.253a.5.5 0 0 0-.142.283l-.66 4.62a.75.75 0 0 0 .849.848l4.62-.66a.5.5 0 0 0 .282-.141l7.779-7.778a3.5 3.5 0 0 0 0-4.95m-4.243.707a2.5 2.5 0 0 1 3.535 3.536l-.707.707-3.535-3.536zm-1.414 1.414-6.246 6.246-.59 4.125 4.125-.59 6.246-6.245z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcPencilLight;
