import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcTrashBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M13 2.987v-.691A.3.3 0 0 0 12.7 2h-1.4c-.166 0-.3.133-.3.296v.69H3.3c-.166 0-.3.133-.3.297v1.381c0 .164.134.296.3.296h17.4c.166 0 .3-.132.3-.296V3.283a.3.3 0 0 0-.3-.296zM17.525 20.027 18.48 6.42a.3.3 0 0 1 .299-.276h1.394c.174 0 .311.146.3.317L19.41 21.724a.3.3 0 0 1-.3.276H4.88a.3.3 0 0 1-.299-.276L3.521 6.461a.3.3 0 0 1 .3-.317h1.394c.158 0 .289.12.3.276l.952 13.607z"
      />
      <path
        fill="currentColor"
        d="M10.7 6.144c.166 0 .3.133.3.296v10.262a.3.3 0 0 1-.3.296H9.3a.3.3 0 0 1-.3-.296V6.44c0-.163.134-.296.3-.296zM14.7 6.144c.166 0 .3.133.3.296v10.262a.3.3 0 0 1-.3.296h-1.4a.3.3 0 0 1-.3-.296V6.44c0-.163.134-.296.3-.296z"
      />
    </svg>
  );
});
export default SvgIcTrashBold;
