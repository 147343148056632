import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcPictureRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path fill="currentColor" d="M16.05 10a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.7 3.5a.8.8 0 0 1 .8-.8h17a.8.8 0 0 1 .8.8v17a.8.8 0 0 1-.8.8h-17a.8.8 0 0 1-.8-.8zm1.6.8v8.99l3.208-3.85a1.3 1.3 0 0 1 1.983-.017L18.366 19.7H19.7V4.3zm0 15.4v-3.91l4.21-5.053 7.742 8.963z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcPictureRegular;
