'use client';

import { Banners, fetchHomePcBannerList } from '@29cm/contexts-banners-services';
import { Intersection } from '@29cm/contexts-common-features';
import { useFetchInfinite } from '@29cm/contexts-common-hooks';
import { getCampaignLink } from '@29cm/contexts-common-utils';
import { Link } from '@29cm/contexts-common-views';
import { desktopBannerSize } from '@29cm/contexts-home/constants';
import { Banner } from '@29cm/contexts-home/views';
import { Result } from '@29cm/contexts-http/interfaces';
import { flatResults } from '@29cm/contexts-http/utils';

interface BannerListProps {
  className?: string;
  initialBanners?: Result<Banners, Error>;
}

export const BannerList = ({ className, initialBanners }: BannerListProps) => {
  const { results: desktopBannerResults, fetchNextPage: readMoreDesktopBanner } = useFetchInfinite(
    fetchHomePcBannerList,
    (pageParams?: { offset: number }) => ({
      offset: pageParams?.offset ?? 0,
      limit: desktopBannerSize,
    }),
    {
      initialResult: initialBanners,
      getNextPageParams: (incoming, existing) => {
        if (incoming.success && !incoming.data.next) {
          return;
        }

        return {
          offset: existing.length * desktopBannerSize,
        };
      },
    },
  );

  const bannerList = flatResults(desktopBannerResults, (data) => data.results);

  return (
    <ul className={className}>
      {bannerList.map((item, index) => {
        const href = getCampaignLink(item.linkType, item.linkValue);

        return (
          <Link key={item.bannerId} href={href}>
            <Banner className="aspect-[5/6]" banner={item} position={index + 1} />
          </Link>
        );
      })}
      <Intersection onView={readMoreDesktopBanner} rootMargin="600px" />
    </ul>
  );
};
