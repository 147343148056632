import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcFilterLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M17 3a.5.5 0 0 1 .5.5v2h3v1h-3v2a.5.5 0 0 1-1 0v-5A.5.5 0 0 1 17 3M7.5 9.5a.5.5 0 0 0-1 0v2H4v1h2.5v2a.5.5 0 0 0 1 0zM7 18.5H3.5v-1H7zM11 15a.5.5 0 0 1 .5.5v2h9v1h-9v2a.5.5 0 0 1-1 0v-5a.5.5 0 0 1 .5-.5M13 6.5H4v-1h9zM11 12.5h9.5v-1H11z"
      />
    </svg>
  );
});
export default SvgIcFilterLight;
