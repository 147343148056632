import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcRemoveLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="m7.88 8.587.707-.707L12 11.287l3.396-3.392.707.708-3.395 3.39 3.395 3.39-.707.708L12 12.7l-3.412 3.407-.707-.708 3.411-3.406z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-1 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcRemoveLight;
