import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcWonRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18 13.05a.25.25 0 0 1-.25.25h-1.73l-.722 2.519a.25.25 0 0 1-.24.181h-1.491a.25.25 0 0 1-.24-.181l-.722-2.519h-1.21l-.721 2.519a.25.25 0 0 1-.24.181H8.941a.25.25 0 0 1-.24-.181L7.981 13.3H6.25a.25.25 0 0 1-.25-.25v-1.1a.25.25 0 0 1 .25-.25h1.273L6.84 9.319A.25.25 0 0 1 7.081 9h1.04a.25.25 0 0 1 .24.181l.722 2.519h1.21l.721-2.519a.25.25 0 0 1 .24-.181h1.492a.25.25 0 0 1 .24.181l.721 2.519h1.21l.721-2.519a.25.25 0 0 1 .24-.181h1.04a.25.25 0 0 1 .24.319l-.68 2.381h1.272a.25.25 0 0 1 .25.25zm-3.54.25h-.295l.147.514zm-2.313-1.6L12 11.186l-.147.514zm-2.312 1.6h-.294l.147.514z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10m0-1.6a8.4 8.4 0 1 0 0-16.8 8.4 8.4 0 0 0 0 16.8"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcWonRegular;
