import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcMyRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.8 3.25v-.8h-1.6v6.6h1.6v-5.8m-8 17.5v-8.2h14.4v9h1.6V12c0-.58-.47-1.05-1.05-1.05H4.25A1.05 1.05 0 0 0 3.2 12v9.55h1.6z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcMyRegular;
