import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcGiftLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.399 4.35c.556.44 1.104.944 1.601 1.438a19 19 0 0 1 1.601-1.438c.686-.541 1.422-1.015 2.118-1.228.71-.218 1.483-.186 2.044.455.444.507.675 1.317.726 2.422h2.261a.75.75 0 0 1 .75.75v2.5a.75.75 0 0 1-.75.75H19.5v10.25a.75.75 0 0 1-.75.75H5.25a.75.75 0 0 1-.75-.75V10H3.25a.75.75 0 0 1-.75-.75v-2.5A.75.75 0 0 1 3.25 6h2.261c.051-1.105.282-1.915.726-2.422.561-.641 1.334-.673 2.044-.455.696.213 1.432.687 2.118 1.228M18.5 10h-6v10h6zm-7 0v10h-6V10zm0-1V7h-8v2h8m-.722-3a16 16 0 0 0-.999-.865c-.655-.518-1.275-.899-1.791-1.057-.501-.154-.796-.075-.999.158-.221.253-.425.778-.477 1.763zm2.444 0a16 16 0 0 1 .999-.865c.656-.518 1.275-.899 1.791-1.057.501-.154.796-.075.999.158.221.253.425.778.477 1.763zM20.5 9V7h-8v2h8"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcGiftLight;
