import UAParser from 'ua-parser-js';
import { isServer } from './isServer';

interface UserAgent {
  ua: string;
  browser: {
    name?: string;
    version?: string;
    major?: string;
  };
  os: {
    name?: string;
    version?: string;
  };
}

export const getUserAgent = (): UserAgent => {
  if (isServer()) {
    return UAParser('node');
  }

  return UAParser(window.navigator?.userAgent);
};
