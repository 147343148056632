import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcEyeOffLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 9.365c-.701 0-1.36.272-1.856.77a2.6 2.6 0 0 0-.769 1.856c0 .703.272 1.36.769 1.857l-.708.707a3.6 3.6 0 0 1-1.061-2.564c0-.967.378-1.88 1.061-2.563A3.6 3.6 0 0 1 12 8.365c.967 0 1.879.378 2.564 1.063l-.708.707A2.6 2.6 0 0 0 12 9.365M4.665 19.34 19.732 4.273l.707.708L5.372 20.047z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.575 16.842c-2.138-.878-4.023-2.564-5.307-4.85C5.207 8.535 8.518 6.452 12 6.452c1.869 0 3.689.6 5.273 1.69l.718-.717c-1.771-1.263-3.839-1.973-5.99-1.973-3.985 0-7.679 2.432-9.744 6.303l-.126.235.126.236c1.31 2.455 3.277 4.332 5.557 5.376zm1.053.361-.8.801a10 10 0 0 0 3.172.524c3.984 0 7.678-2.432 9.743-6.301l.126-.236-.126-.235a12.8 12.8 0 0 0-2.957-3.71l-.71.709a11.7 11.7 0 0 1 2.656 3.236c-1.939 3.454-5.25 5.537-8.732 5.537-.805 0-1.6-.11-2.372-.325"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcEyeOffLight;
