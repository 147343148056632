import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcHangerBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.602 6.872A1.398 1.398 0 1 1 12 8.27a1 1 0 0 0-1 1v2.225l-8.706 7.264a1 1 0 0 0 .64 1.767h18.131a1 1 0 0 0 .64-1.767L13 11.495V10.12a3.4 3.4 0 0 0-1-6.646 3.4 3.4 0 0 0-3.398 3.398z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcHangerBoldFill;
