import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcLawBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13 5.267V20h4.25a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25H6.75a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25H11V5.267H7.795L8.837 9.25h1.713V10a4.15 4.15 0 1 1-8.3 0v-.75h1.912l1.366-5.22a1 1 0 0 1 .367-.56 1 1 0 0 1 .605-.203H11V2.25a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25v1.017h4.5a1 1 0 0 1 .974.77l1.363 5.213h1.913V10a4.15 4.15 0 0 1-8.3 0v-.75h1.713l1.042-3.983zm-6.5 2.95.27 1.033h-.54zM17.77 9.25l-.27-1.032-.27 1.032z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcLawBoldFill;
