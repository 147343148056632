import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcCameraBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.075 5.625h3.647L8.665 3.67a.57.57 0 0 1 .408-.17h5.854c.154 0 .3.061.408.17l1.943 1.955h3.647c.318 0 .575.257.575.575v13.725a.575.575 0 0 1-.575.575H3.075a.575.575 0 0 1-.575-.575V6.2c0-.318.257-.575.575-.575M15.75 13.25a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcCameraBoldFill;
