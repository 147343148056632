import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcHeartBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 6.417C11.193 4.696 9.49 3.5 7.5 3.5c-2.779 0-5 2.335-5 5.172 0 1.127.461 2.143.894 2.853A8 8 0 0 0 4.2 12.62l.06.065.016.019.005.005.002.002L12 20.72l7.717-8.01.003-.002.005-.005.017-.019.059-.065q.077-.084.202-.238c.164-.203.383-.496.603-.856.433-.71.894-1.726.894-2.853 0-2.838-2.221-5.172-5-5.172-1.989 0-3.692 1.196-4.5 2.917"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcHeartBoldFill;
