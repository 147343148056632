import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcHangerRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.402 6.872A1.598 1.598 0 1 1 12 8.47a.8.8 0 0 0-.8.8v2.318l-8.778 7.324a.8.8 0 0 0 .513 1.414h18.13a.8.8 0 0 0 .512-1.414L12.8 11.588V9.97a3.2 3.2 0 1 0-3.998-3.097zm8.456 11.854H5.142L12 13.005z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcHangerRegular;
