import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcCommentLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4 4.46v12.033h13.569L20 18.453V4.46zm-1-.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 .75.75v16.335l-3.784-3.052H3.75a.75.75 0 0 1-.75-.75z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M9.053 10.477a.907.907 0 1 1-1.813 0 .907.907 0 0 1 1.813 0M12.907 10.477a.907.907 0 1 1-1.814 0 .907.907 0 0 1 1.814 0M16.76 10.477a.907.907 0 1 1-1.813 0 .907.907 0 0 1 1.813 0"
      />
    </svg>
  );
});
export default SvgIcCommentLight;
