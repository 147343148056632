import { getImageUrl } from '@29cm/contexts-common-utils';
import NextImage, { ImageProps as NextImageProps } from 'next/image';

export interface ImageProps
  extends Pick<NextImageProps, 'className' | 'alt' | 'width' | 'height' | 'quality' | 'fill' | 'priority'> {
  src: string;
}

export const Image = ({ className, src, alt, width, height, quality = 80, fill, priority }: ImageProps) => {
  return (
    <NextImage
      className={className}
      src={getImageUrl(src)}
      alt={alt}
      width={width}
      height={height}
      quality={quality}
      fill={fill}
      priority={priority}
    />
  );
};
