import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcSpaceRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <g className="ic-29space-regular">
        <g fill="currentColor" className="vector">
          <path
            fillRule="evenodd"
            d="M12.464 13.381c0 1.077.826 1.955 1.882 2.086l-1.47 2.082h1.56l2.08-3.017c.234-.338.359-.74.359-1.15 0-1.196-.995-2.152-2.206-2.152s-2.205.956-2.205 2.151m2.205.935a.934.934 0 0 1-.932-.935c0-.516.418-.935.932-.935.515 0 .933.419.933.935a.934.934 0 0 1-.933.935"
            clipRule="evenodd"
          />
          <path d="M9.777 13.714a.71.71 0 0 0 .196-.491.775.775 0 0 0-.774-.777.775.775 0 0 0-.773.777v.243H7.153v-.243c0-1.108.924-1.993 2.046-1.993s2.046.885 2.046 1.993c0 .485-.186.952-.52 1.303l-1.714 1.808h2.234v1.215H7.153v-1.07z" />
          <path
            fillRule="evenodd"
            d="M12.673 3.255a1.016 1.016 0 0 0-1.346 0l-8.482 7.498c-.22.194-.345.472-.345.765v8.461A1.02 1.02 0 0 0 3.518 21h16.964a1.02 1.02 0 0 0 1.018-1.02v-8.462a1.02 1.02 0 0 0-.345-.765zM4.051 11.76 12 4.733l7.949 7.027v7.685H4.051z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
});
export default SvgIcSpaceRegular;
