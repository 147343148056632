import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcCameraLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.278 6.625a1 1 0 0 1-.71-.295L14.75 4.5h-5.5L7.431 6.33a1 1 0 0 1-.71.295H3.5V19.5h17V6.625zm-10.556-1H3.075A.575.575 0 0 0 2.5 6.2v13.725c0 .318.257.575.575.575h17.85a.575.575 0 0 0 .575-.575V6.2a.575.575 0 0 0-.575-.575h-3.647L15.335 3.67a.57.57 0 0 0-.408-.17H9.073c-.154 0-.3.061-.408.17z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.278 5.625h3.647c.318 0 .575.257.575.575v13.725a.575.575 0 0 1-.575.575H3.075a.575.575 0 0 1-.575-.575V6.2c0-.318.257-.575.575-.575h3.647l1.818-1.83.125-.125a.57.57 0 0 1 .408-.17h5.854c.154 0 .3.061.408.17zm-9.846.705a1 1 0 0 1-.71.295H3.5V19.5h17V6.625h-3.222a1 1 0 0 1-.71-.295L14.75 4.5h-5.5zM12 16a3 3 0 1 0 0-6 3 3 0 0 0 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcCameraLight;
