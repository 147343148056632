'use client';

import { transparentFallbackImageSrc } from '@29cm/contexts-common-constants';
import { getCampaignLink, getImageUrl, isMdScreen } from '@29cm/contexts-common-utils';
import { Link } from '@29cm/contexts-common-views';
import { trackClickFeedProductEvent } from '@29cm/contexts-events/services';
import { Feed, FeedProduct } from '@29cm/contexts-feeds-services';
import { ProductCardWithHeart } from '@29cm/contexts-products-features';

interface Props {
  product: FeedProduct;
  feed: Feed;
}

export const MultiProductCardCarouseItem = ({ feed, product }: Props) => {
  const { images, frontBrand, name, sale, heartCount, reviewCount, id } = product;
  const [image] = images;

  const imageUrl = image !== undefined ? `${getImageUrl(image.uploadName)}?width=300` : transparentFallbackImageSrc;
  const href = getCampaignLink('product', id);

  const handleClick = () => {
    // NOTE: 모바일에서만 해당 이벤트를 수집합니다.
    if (isMdScreen()) {
      return;
    }

    trackClickFeedProductEvent({ feed, product });
  };

  return (
    <Link href={href} onClick={handleClick}>
      <ProductCardWithHeart
        productId={id}
        imgUrl={imageUrl}
        productName={name}
        price={sale.totalSellPrice}
        discount={sale.totalSaleRate}
        brandName={frontBrand.nameKor}
        likeAmount={heartCount}
        reviewAmount={reviewCount}
      />
    </Link>
  );
};
