import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcCameraRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.278 7.225a1.6 1.6 0 0 1-1.135-.472L14.5 5.1H9.499L7.857 6.753a1.6 1.6 0 0 1-1.135.472H4.1V18.9h15.8V7.225zm-10.556-1.6H3.075A.575.575 0 0 0 2.5 6.2v13.725c0 .318.257.575.575.575h17.85a.575.575 0 0 0 .575-.575V6.2a.575.575 0 0 0-.575-.575h-3.647L15.335 3.67a.57.57 0 0 0-.408-.17H9.073c-.154 0-.3.061-.408.17z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 15.4a2.4 2.4 0 1 0 0-4.8 2.4 2.4 0 0 0 0 4.8m0 1.6a4 4 0 1 0 0-8 4 4 0 0 0 0 8"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcCameraRegular;
