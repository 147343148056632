import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcLockoffLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path fill="currentColor" d="M12 12.75a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2a.5.5 0 0 1 .5-.5" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.5 9.735v.015H19a.75.75 0 0 1 .75.75V21a.75.75 0 0 1-.75.75H5a.75.75 0 0 1-.75-.75V10.5A.75.75 0 0 1 5 9.75h1.5v-2a5.5 5.5 0 0 1 9.965-3.212l-.776.634A4.5 4.5 0 0 0 7.5 7.75v2h9v-.015zM5.25 20.75h13.5v-10H5.25z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcLockoffLight;
