import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcInfoCircleRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-1.6 0a8.4 8.4 0 1 1-16.8 0 8.4 8.4 0 0 1 16.8 0"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M11.382 16.765a.25.25 0 0 1-.25-.25v-5.863a.25.25 0 0 1 .25-.25h1.234a.25.25 0 0 1 .25.25v5.863a.25.25 0 0 1-.25.25zM12.9 8.7a.9.9 0 1 1-1.8 0 .9.9 0 0 1 1.8 0"
      />
    </svg>
  );
});
export default SvgIcInfoCircleRegular;
