import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcWonLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18m0 1c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10m6-9.25a.25.25 0 0 1-.25.25H16l-.882 2.825a.25.25 0 0 1-.239.175h-.964a.25.25 0 0 1-.238-.174L12.773 13h-1.546l-.904 2.826a.25.25 0 0 1-.238.174h-.964a.25.25 0 0 1-.239-.175L8 13H6.25a.25.25 0 0 1-.25-.25v-.5a.25.25 0 0 1 .25-.25h1.437l-.836-2.675A.25.25 0 0 1 7.09 9h.438a.25.25 0 0 1 .238.174L8.669 12h1.867l.903-2.826A.25.25 0 0 1 11.677 9h.646a.25.25 0 0 1 .238.174L13.464 12h1.867l.903-2.826A.25.25 0 0 1 16.472 9h.438a.25.25 0 0 1 .239.325L16.313 12h1.437a.25.25 0 0 1 .25.25zm-2.989.25h-1.227l.614 1.919zm-2.557-1L12 10.581 11.546 12zm-2.238 1H8.989l.613 1.919z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcWonLight;
