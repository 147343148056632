import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcHangerLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.102 6.872A1.898 1.898 0 1 1 12 8.77a.5.5 0 0 0-.5.5v2.459l-8.885 7.413a.5.5 0 0 0 .32.884h18.13a.5.5 0 0 0 .32-.884L12.5 11.73V9.727a2.899 2.899 0 1 0-3.398-2.855zm9.583 12.154H4.315L12 12.614z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcHangerLight;
