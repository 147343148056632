import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcDownloadLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.5 14.377V3h1v11.377l4.166-3.749.668.744-5.334 4.8-5.334-4.8.668-.744zM18 20v1H6v-1z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcDownloadLight;
