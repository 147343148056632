import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcPictureBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path fill="currentColor" d="M16 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.5 3.5a1 1 0 0 1 1-1h17a1 1 0 0 1 1 1v17a1 1 0 0 1-1 1h-17a1 1 0 0 1-1-1zm2 1v8.238l2.854-3.425a1.5 1.5 0 0 1 2.288-.02L18.458 19.5H19.5v-15zm0 15v-3.638l4.014-4.816 7.301 8.454z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcPictureBold;
