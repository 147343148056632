'use client';

import { Banner as BannerType } from '@29cm/contexts-banners-services';
import { transparentFallbackImageSrc } from '@29cm/contexts-common-constants';
import { Intersection } from '@29cm/contexts-common-features';
import { ResponsiveImage, ResponsiveImageProps } from '@29cm/contexts-common-views';
import { trackClickHomeBannerEvent, trackViewHomeBannerEvent } from '@29cm/contexts-events/services';
import { HTMLAttributes, memo } from 'react';
import { tv } from 'tailwind-variants';

const bannerStyles = tv({
  slots: {
    layout: 'relative overflow-hidden',
    image: 'h-full w-full object-cover',
    layer:
      'max-[375px]gap-8 absolute inset-x-[42px] bottom-[83px] flex flex-col gap-12 max-[375px]:inset-x-20 max-[375px]:bottom-[50px]',
    title: 'text-[32px] font-bold leading-[136%] max-[375px]:text-[30px]',
    subtitle: 'line-clamp-2 text-[18px] font-medium leading-[150%] max-[375px]:text-[14px]',
  },
  variants: {
    color: {
      white: {
        title: 'text-primary-dark',
        subtitle: 'text-primary-dark',
      },
      black: {
        title: 'text-primary-light',
        subtitle: 'text-primary-light',
      },
    },
  },
});

const Layout = ({ className, children }: Pick<HTMLAttributes<HTMLDivElement>, 'children' | 'className'>) => {
  const { layout } = bannerStyles();

  return <div className={layout({ class: className })}>{children}</div>;
};

const Image = ({
  className,
  src,
  alt,
  priority,
}: Pick<ResponsiveImageProps, 'className' | 'src' | 'alt' | 'priority'>) => {
  const { image } = bannerStyles();

  return (
    <ResponsiveImage
      className={image({ class: className })}
      sizes={{
        sm: '100vw',
        md: '50vw',
      }}
      fill
      src={src}
      alt={alt}
      priority={priority}
    />
  );
};

const Layer = ({ className, children }: Pick<HTMLAttributes<HTMLDivElement>, 'children' | 'className'>) => {
  const { layer } = bannerStyles();

  return <div className={layer({ class: className })}>{children}</div>;
};

const Title = ({
  className,
  children,
  color,
}: Pick<HTMLAttributes<HTMLParagraphElement>, 'children' | 'className'> & { color: 'white' | 'black' }) => {
  const { title } = bannerStyles();

  return <p className={title({ color, class: className })}>{children}</p>;
};

const Subtitle = ({
  className,
  children,
  color,
}: Pick<HTMLAttributes<HTMLParagraphElement>, 'children' | 'className'> & { color: 'white' | 'black' }) => {
  const { subtitle } = bannerStyles();

  return <p className={subtitle({ color, class: className })}>{children}</p>;
};

export interface BannerProps extends Pick<ResponsiveImageProps, 'priority'> {
  className?: string;
  banner: BannerType;
  position: number;
}

export const Banner = memo(({ className, banner, priority, position }: BannerProps) => {
  const { bannerTitle, contentsColor, bannerContents, isBannerTitleHidden, bannerImageList } = banner;

  const [image] = bannerImageList;

  const imageSrc = image?.fileUploadName ?? transparentFallbackImageSrc;

  const textColor = contentsColor === 'white' ? 'white' : 'black';

  const handleView = () => trackViewHomeBannerEvent({ banner, position });

  const handleClick = () => trackClickHomeBannerEvent({ banner, position });

  return (
    <Intersection onView={handleView} threshold={0.5}>
      <div onClick={handleClick}>
        <Layout className={className}>
          <Image alt={bannerTitle} src={imageSrc} priority={priority} />
          {!isBannerTitleHidden ? (
            <Layer>
              <Title color={textColor}>{bannerTitle}</Title>
              <Subtitle color={textColor}>{bannerContents}</Subtitle>
            </Layer>
          ) : null}
        </Layout>
      </div>
    </Intersection>
  );
});
