import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcDeliveryLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.5 3a1 1 0 0 1 1-1H22v1h-6.5v16.5H22v1h-9.337a3.5 3.5 0 0 1-6.326 0H3a1 1 0 0 1-1-1v-5.64a1 1 0 0 1 .684-.948l3.951-1.317 2.277-5.009A1 1 0 0 1 9.822 6H14.5zM7.05 19.5a2.496 2.496 0 0 0 2.45 2A2.496 2.496 0 0 0 12 19a2.5 2.5 0 1 0-4.95.5m5.915 0Q13 19.255 13 19a3.5 3.5 0 1 0-6.965.5H3v-5.64l4.081-1.36H14.5v7zM14.5 7H9.822l-2.045 4.5H14.5z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcDeliveryLight;
