import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcLawLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M12.5 20.5V4.767h5a.5.5 0 0 0 0-1h-5V2.25a.25.25 0 0 0-.25-.25h-.5a.25.25 0 0 0-.25.25v1.517h-5a.5.5 0 0 0 0 1h5V20.5H6.75a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h10.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.984 4.14a.5.5 0 0 0-.968 0L4.614 9.5H2.5v.5a3.9 3.9 0 0 0 7.8 0v-.5H8.386zm.368 5.36L6.5 6.242 5.648 9.5zM6.4 12.9a2.9 2.9 0 0 1-2.857-2.4h5.714A2.9 2.9 0 0 1 6.4 12.9M17.984 4.14a.5.5 0 0 0-.968 0L15.614 9.5H13.5v.5a3.9 3.9 0 0 0 7.8 0v-.5h-1.914zM17.5 6.242l.852 3.258h-1.704zM14.543 10.5a2.9 2.9 0 0 0 5.714 0z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcLawLight;
