import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcQuestionBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16m0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.477 14.167v.285h-2.012v-.287c.007-1.005.207-1.587.546-1.994.168-.201.364-.353.573-.49a10 10 0 0 1 .322-.2q.01-.008.019-.012.155-.093.316-.195c.614-.417 1.047-.897 1.04-1.594v-.002c.006-.775-.567-1.277-1.301-1.277-.334 0-.657.113-.903.34-.243.224-.419.57-.443 1.054l-.007.138H8.5l.004-.15c.026-1.077.423-1.903 1.06-2.458.634-.552 1.494-.825 2.43-.825 1.023 0 1.897.298 2.52.846.624.55.982 1.342.986 2.301v.002c-.004.655-.161 1.202-.447 1.665-.284.46-.692.829-1.184 1.135-.137.088-.272.168-.395.241l-.193.116a2.5 2.5 0 0 0-.434.316c-.222.214-.362.496-.37 1.045m-.893.643c.692.008 1.277.609 1.277 1.337 0 .74-.583 1.353-1.279 1.353-.716 0-1.301-.61-1.294-1.353a1.32 1.32 0 0 1 1.292-1.337z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcQuestionBold;
