import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcPencilBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M18.835 5.081a3.75 3.75 0 0 0-5.303 0l-.707.707 5.303 5.304.707-.707a3.75 3.75 0 0 0 0-5.304M16.997 12.223 11.694 6.92l-5.94 5.94a.75.75 0 0 0-.212.424l-.66 4.62a1 1 0 0 0 1.131 1.13l4.62-.659a.75.75 0 0 0 .424-.212z"
      />
    </svg>
  );
});
export default SvgIcPencilBoldFill;
