import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcFoldChevronRightRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path fill="currentColor" d="m20 12-8.6-8.6H9.137l8.6 8.6-8.6 8.6H11.4z" />
      <path fill="currentColor" d="m5.137 20.6 8.6-8.6-8.6-8.6H7.4L16 12l-8.6 8.6z" />
    </svg>
  );
});
export default SvgIcFoldChevronRightRegular;
