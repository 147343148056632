import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcQuestionRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 20.4a8.4 8.4 0 1 0 0-16.8 8.4 8.4 0 0 0 0 16.8m0 1.6c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M10.546 14.225c.016-2.026.816-2.233 1.785-2.845.654-.437 1.161-.97 1.154-1.764.007-.89-.67-1.463-1.508-1.463-.762 0-1.5.509-1.554 1.574H8.5C8.554 7.589 10.092 6.5 11.992 6.5c2.077 0 3.5 1.192 3.508 3.084-.008 1.295-.63 2.138-1.63 2.75-.893.564-1.508.7-1.524 1.891v.143h-1.8zm-.184 2.035a1.22 1.22 0 0 1 1.2-1.224c.639.008 1.185.556 1.185 1.224 0 .684-.546 1.24-1.185 1.24-.662 0-1.208-.556-1.2-1.24"
      />
    </svg>
  );
});
export default SvgIcQuestionRegular;
