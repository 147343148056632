'use client';

import { useIsWebview } from '@29cm/contexts-runtime/hooks';
import { useIsMounted } from '@29cm/hooks-dom';
import { PropsWithChildren, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;

  /**
   * @description webview 환경이 아닐 경우 노출할 컴포넌트를 전달합니다.
   */
  fallback?: ReactNode;
};

export const Webview = ({ className, fallback, children }: PropsWithChildren<Props>) => {
  const isMounted = useIsMounted();
  const isWebview = useIsWebview();

  /**
   * server-side 에서는 모든 컴포넌트를 렌더링해 두고, client-side 에서는 웹뷰 환경에 해당하는 컴포넌트만 렌더링합니다.
   */
  const shouldRenderChildren = !isMounted || isWebview;
  const shouldRenderFallback = (!isMounted || !isWebview) && fallback !== undefined;

  return (
    <>
      {shouldRenderChildren ? <div className={twMerge(`hidden webview:block`, className)}>{children}</div> : null}
      {shouldRenderFallback ? <div className={twMerge(`block webview:hidden`, className)}>{fallback}</div> : null}
    </>
  );
};
