import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcLockLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path fill="currentColor" d="M12.5 13.25a.5.5 0 0 0-1 0v2a.5.5 0 0 0 1 0z" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.5 7.75a5.5 5.5 0 1 1 11 0v2H19a.75.75 0 0 1 .75.75V21a.75.75 0 0 1-.75.75H5a.75.75 0 0 1-.75-.75V10.5A.75.75 0 0 1 5 9.75h1.5zm10 0v2h-9v-2a4.5 4.5 0 0 1 9 0m-11.25 3h13.5v10H5.25z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcLockLight;
