import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcBoxLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.5 4.5v15h15v-15H15V12h-1V4.5h-4V12H9V4.5zm-1-.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75v15.5a.75.75 0 0 1-.75.75H4.25a.75.75 0 0 1-.75-.75z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcBoxLight;
