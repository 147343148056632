import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcSearchLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.9 4a6.9 6.9 0 1 0 0 13.8 6.9 6.9 0 0 0 0-13.8M3 10.9a7.9 7.9 0 1 1 13.82 5.23l4.033 4.016-.706.708-4.035-4.017A7.9 7.9 0 0 1 3 10.9"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcSearchLight;
