import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcScrapRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 16.058 20 21l-.002-.681.005-16.637V3.67a.68.68 0 0 0-.203-.47.7.7 0 0 0-.479-.2H4.694a.683.683 0 0 0-.69.681L4 21zM5.6 18.13l6.4-3.954 6.4 3.953.003-13.53H5.604z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcScrapRegular;
