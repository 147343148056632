import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcCautionBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10m-1-8.5v-7h2v7zm-.5 2.514c0-.827.687-1.5 1.514-1.514a1.52 1.52 0 0 1 1.486 1.514c0 .813-.673 1.5-1.486 1.486a1.5 1.5 0 0 1-1.514-1.486"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcCautionBoldFill;
