import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcSearchListBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.659 4A6.659 6.659 0 1 0 18.6 16.026l2.694 2.683 1.412-1.418-2.69-2.677A6.659 6.659 0 0 0 14.659 4M10 10.659a4.659 4.659 0 1 1 9.318 0 4.659 4.659 0 0 1-9.318 0"
        clipRule="evenodd"
      />
      <path fill="currentColor" d="M2 5v2h5V5zM2 17v2h8v-2zM2 13v-2h5v2z" />
    </svg>
  );
});
export default SvgIcSearchListBoldFill;
