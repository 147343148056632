import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcBellBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.237 4C8.497 2.722 10.21 2 12 2c1.79 0 3.502.721 4.762 2a6.86 6.86 0 0 1 1.965 4.813v4.57l1.862 3.88H3.41l1.862-3.88v-4.57c0-1.802.705-3.533 1.965-4.812"
        clipRule="evenodd"
      />
      <path fill="currentColor" d="M7.732 18.158C7.732 20.406 9.778 22 12 22s4.267-1.594 4.267-3.842H7.732" />
    </svg>
  );
});
export default SvgIcBellBoldFill;
