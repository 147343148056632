import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcFoldChevronLeftBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path fill="currentColor" d="m4 12 8.7-8.7h2.828l-8.7 8.7 8.7 8.7H12.7z" />
      <path fill="currentColor" d="m19.528 20.7-8.7-8.7 8.7-8.7H16.7L8 12l8.7 8.7z" />
    </svg>
  );
});
export default SvgIcFoldChevronLeftBoldFill;
