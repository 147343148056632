import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcHeartBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.5 5C5.603 5 4 6.61 4 8.672c0 .735.31 1.472.675 2.072a6.5 6.5 0 0 0 .681.918l.007.008L12 18.559l6.636-6.889h.001l.007-.008.038-.041q.054-.06.154-.183c.133-.164.311-.402.489-.694.366-.6.675-1.337.675-2.072C20 6.609 18.398 5 16.5 5S13 6.61 13 8.672h-2C11 6.609 9.398 5 7.5 5m4.5.412C11.01 3.96 9.375 3 7.5 3 4.428 3 2 5.577 2 8.672c0 1.258.512 2.366.967 3.113a8.5 8.5 0 0 0 .859 1.167l.066.073.02.022.007.007.003.002L12 21.441l8.076-8.383.005-.004.006-.007.02-.022q.026-.027.067-.073a8.482 8.482 0 0 0 .858-1.167c.456-.747.968-1.855.968-3.113C22 5.576 19.572 3 16.5 3c-1.875 0-3.51.96-4.5 2.412"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcHeartBold;
