import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcFilterRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17 2.7a.8.8 0 0 1 .8.8v5a.8.8 0 0 1-1.6 0v-5a.8.8 0 0 1 .8-.8"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17 5.2h3.5v1.6H17zM7 8.7a.8.8 0 0 1 .8.8v5a.8.8 0 0 1-1.6 0v-5a.8.8 0 0 1 .8-.8M7 18.8H3.5v-1.6H7z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7 12.8H3.5v-1.6H7zM11 14.7a.8.8 0 0 1 .8.8v5a.8.8 0 0 1-1.6 0v-5a.8.8 0 0 1 .8-.8"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20.5 18.8H11v-1.6h9.5zM12.5 6.8h-9V5.2h9zM20.5 12.8H11v-1.6h9.5z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcFilterRegular;
