import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcCameraBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.556 7.368a2.1 2.1 0 0 1-1.494-.626l-1.605-1.624H9.543L7.938 6.742a2.1 2.1 0 0 1-1.494.626H4.105v11.514h15.79V7.368zM6.444 5.25H2.605A.607.607 0 0 0 2 5.859V20.39c0 .336.271.609.605.609h18.79a.607.607 0 0 0 .605-.609V5.86a.607.607 0 0 0-.605-.609h-3.84l-2.044-2.07a.6.6 0 0 0-.43-.18H8.92a.6.6 0 0 0-.43.18z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 15.177a2.11 2.11 0 0 0 2.105-2.118c0-1.17-.942-2.118-2.105-2.118a2.11 2.11 0 0 0-2.105 2.118c0 1.17.942 2.118 2.105 2.118m0 2.117c2.325 0 4.21-1.896 4.21-4.235S14.326 8.824 12 8.824s-4.21 1.896-4.21 4.235 1.885 4.235 4.21 4.235"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcCameraBold;
