import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcHeartRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.5 4.8c-2.015 0-3.7 1.706-3.7 3.872 0 .787.33 1.561.704 2.176a6.7 6.7 0 0 0 .706.95l.008.01h.001L12 18.848l6.78-7.038.002-.001.008-.01q.013-.012.04-.044.059-.063.162-.19c.137-.17.32-.415.503-.716.375-.615.705-1.389.705-2.176 0-2.166-1.685-3.872-3.7-3.872s-3.7 1.706-3.7 3.872h-1.6C11.2 6.506 9.515 4.8 7.5 4.8m4.5.982C11.07 4.238 9.412 3.2 7.5 3.2c-2.955 0-5.3 2.48-5.3 5.472 0 1.205.492 2.277.938 3.01a8.3 8.3 0 0 0 .837 1.137l.064.07.019.02.006.006.002.003L12 21.153l7.933-8.234v-.001l.003-.002.006-.007.02-.02.063-.07a8.265 8.265 0 0 0 .837-1.138c.446-.732.938-1.804.938-3.009 0-2.993-2.345-5.472-5.3-5.472-1.912 0-3.569 1.038-4.5 2.582"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcHeartRegular;
