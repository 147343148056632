import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcTrashBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M13 2.296v.69h7.7c.166 0 .3.133.3.297v1.381a.3.3 0 0 1-.3.296H3.3a.3.3 0 0 1-.3-.296V3.283c0-.164.134-.296.3-.296H11v-.691c0-.163.134-.296.3-.296h1.4c.166 0 .3.133.3.296M9 16.702V6.144H3.821a.3.3 0 0 0-.299.316l1.059 15.264c.01.155.142.276.3.276h14.23c.157 0 .288-.12.299-.276l1.063-15.263a.3.3 0 0 0-.299-.317H15v10.558a.3.3 0 0 1-.3.296h-1.4a.3.3 0 0 1-.3-.296V6.144h-2v10.558a.3.3 0 0 1-.3.296H9.3a.3.3 0 0 1-.3-.296"
      />
    </svg>
  );
});
export default SvgIcTrashBoldFill;
