import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcCommentBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.75 2.68c-.69 0-1.25.56-1.25 1.25v12.533c0 .69.56 1.25 1.25 1.25h13.29l4.46 3.597V3.93c0-.69-.56-1.25-1.25-1.25zm4.397 6.41a1.107 1.107 0 1 0 0 2.213 1.107 1.107 0 0 0 0-2.213m3.853 0a1.107 1.107 0 1 0 0 2.213 1.107 1.107 0 0 0 0-2.213m3.853 0a1.107 1.107 0 1 0 0 2.214 1.107 1.107 0 0 0 0-2.214"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcCommentBoldFill;
