import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcHouseLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.506 2.768a.75.75 0 0 1 .988 0l7.75 6.781a.75.75 0 0 1 .256.564V20.75a.75.75 0 0 1-.75.75H4.25a.75.75 0 0 1-.75-.75V10.113a.75.75 0 0 1 .256-.564zm.494.896-7.5 6.563V20.5h7V13h1v7.5h7V10.227z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcHouseLight;
