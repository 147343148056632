import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcInfoCircleBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-2 0a8 8 0 1 1-16 0 8 8 0 0 1 16 0"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M11.15 16.763a.25.25 0 0 1-.25-.25V10.65a.25.25 0 0 1 .25-.25h1.715a.25.25 0 0 1 .25.25v5.863a.25.25 0 0 1-.25.25zM13.15 8.75a1.15 1.15 0 1 1-2.3 0 1.15 1.15 0 0 1 2.3 0"
      />
    </svg>
  );
});
export default SvgIcInfoCircleBold;
