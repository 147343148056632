import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcBagRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.621 3.53A4.92 4.92 0 0 1 12 2.2c1.26 0 2.476.474 3.379 1.33a4.53 4.53 0 0 1 1.348 2.46h3.071c.52 0 1.002.416 1.002.989v13.832c0 .58-.48.989-1.002.989H4.191a1 1 0 0 1-.678-.273 1 1 0 0 1-.313-.696V6.979c0-.581.48-.99 1.002-.99h3.071a4.53 4.53 0 0 1 1.348-2.46m.296 2.46h6.166a2.95 2.95 0 0 0-.805-1.3A3.32 3.32 0 0 0 12 3.8c-.862 0-1.681.325-2.278.89a2.95 2.95 0 0 0-.805 1.3M4.8 7.59V20.2h14.4v-5.505H12v-1.6h7.2V7.589z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcBagRegular;
