import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcQuestionLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-10 9a9 9 0 1 0 0-18 9 9 0 0 0 0 18"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M10.782 14.729c.025-1.92.941-2.312 2.143-3.055.872-.542 1.382-1.185 1.382-2.103 0-1.235-1.002-2.061-2.264-2.053-1.175-.008-2.23.743-2.299 2.17H8.5C8.56 7.735 10.09 6.5 12.043 6.5 14.1 6.5 15.5 7.819 15.5 9.621c0 1.219-.588 2.07-1.728 2.788-1.107.684-1.772 1.001-1.798 2.32v.284h-1.193zm-.355 1.886c0-.492.424-.893.934-.901.5.008.915.409.915.901a.9.9 0 0 1-.915.885c-.51.008-.934-.4-.934-.885"
      />
    </svg>
  );
});
export default SvgIcQuestionLight;
