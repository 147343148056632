import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcCheckCalendarLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="m15.325 9.533-4.13 5.041-2.505-3.372-.803.596 2.887 3.887a.5.5 0 0 0 .788.018l4.536-5.536z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.024 4.486V2.102h-1v2.384H4.5c-.551 0-1 .45-1 1v13.912c0 .551.449 1 1 1h15c.551 0 1-.449 1-1V5.486c0-.55-.449-1-1-1h-2.646V2.102h-1v2.384zm-3.524 1h15v13.912h-15z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcCheckCalendarLight;
