import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcEyeOffBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m17.76 7.903-.47.468-8.402 8.404-.505.504-2.928 2.93-.92-.92 2.135-2.136c-1.841-1.106-3.431-2.768-4.545-4.856l-.163-.306.163-.306C4.225 7.75 8.01 5.303 12 5.303c1.794 0 3.544.498 5.12 1.4l2.483-2.483.919.92zM9.437 9.428a3.6 3.6 0 0 0-1.062 2.563 3.6 3.6 0 0 0 .985 2.472l.355-.355.353-.352a2.607 2.607 0 0 1 .076-3.62c.963-.965 2.616-.985 3.62-.077l.707-.707c-1.383-1.286-3.692-1.267-5.034.076"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M19.017 8.06a12.9 12.9 0 0 1 2.858 3.625l.163.306-.163.306c-2.101 3.936-5.885 6.381-9.875 6.381-1.058 0-2.1-.177-3.1-.502z"
      />
    </svg>
  );
});
export default SvgIcEyeOffBoldFill;
