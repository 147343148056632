import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcCheckCalendarBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5 5.986v12.912h14V5.986zm-2-.5c0-.827.673-1.5 1.5-1.5h15c.827 0 1.5.673 1.5 1.5v13.912c0 .827-.673 1.5-1.5 1.5h-15c-.827 0-1.5-.673-1.5-1.5z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.524 2.102v2.884h-2V2.102zM17.354 2.102v2.884h-2V2.102zM11.214 13.411l3.724-4.545 1.547 1.268-4.536 5.536a1 1 0 0 1-1.577-.037l-2.886-3.887 1.605-1.193z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcCheckCalendarBold;
