import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcRedoRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M11.75 4.785V3l3.753 2.75L11.75 8.5V6.386a6.45 6.45 0 1 0 5.708 3.006l1.352-.855a8.05 8.05 0 1 1-7.06-3.752"
      />
    </svg>
  );
});
export default SvgIcRedoRegular;
