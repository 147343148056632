import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcCheckCircleLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m17.381 8.823-6.276 7.4-4.446-4.15.682-.73 3.68 3.434 5.598-6.6z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-1 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcCheckCircleLight;
