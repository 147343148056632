import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcLockoffBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path fill="currentColor" d="M12 12.5a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M19.25 10H8V8a4 4 0 0 1 7.307-2.251l1.552-1.27A6 6 0 0 0 6 8v2.001H4.751a.75.75 0 0 0-.75.75v10.5c0 .414.336.75.75.75h14.5a.75.75 0 0 0 .75-.75v-10.5a.75.75 0 0 0-.75-.75M6 20v-8h12v8z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcLockoffBold;
