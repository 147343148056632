import { Intersection } from '@29cm/contexts-common-features';
import { useFetchInfinite } from '@29cm/contexts-common-hooks';
import { MasonryLayout, MasonryLayoutProps } from '@29cm/contexts-common-views';
import { Feed as FeedType, Feeds, HomeType, fetchFeeds } from '@29cm/contexts-feeds-services';
import { desktopFeedSize } from '@29cm/contexts-home/constants';
import { Result } from '@29cm/contexts-http/interfaces';
import { flatResults } from '@29cm/contexts-http/utils';
import dynamic from 'next/dynamic';
import { ComponentProps, useCallback } from 'react';
import { Feed } from '../Feed';

interface Props {
  className?: string;
  initialFeeds?: Result<Feeds, Error>;
}

export const FeedMasonryLayout = ({ className, initialFeeds }: Props) => {
  const { results: desktopFeedsResults, fetchNextPage: readMoreDesktopFeeds } = useFetchInfinite(
    fetchFeeds,
    (pageParams?: { offset: number }) => ({
      offset: pageParams?.offset ?? 0,
      limit: desktopFeedSize,
      homeType: HomeType.WEB_HOME,
    }),
    {
      initialResult: initialFeeds,
      getNextPageParams: (incoming, existing) => {
        if (incoming.success && !incoming.data.next) {
          return;
        }

        return {
          offset: existing.length * desktopFeedSize,
        };
      },
    },
  );

  const feedList = flatResults(desktopFeedsResults, (data) => data.results);

  const renderItem = useCallback(({ data }: { data: FeedType }) => {
    return <Feed variant={data.type} item={data} />;
  }, []);

  return (
    <section className={className}>
      <Masonry columnCount={2} items={feedList} renderItem={renderItem} />
      <Intersection onView={readMoreDesktopFeeds} rootMargin="600px" />
    </section>
  );
};

const Masonry = dynamic<ComponentProps<(props: MasonryLayoutProps<FeedType>) => ReturnType<typeof MasonryLayout>>>(
  () => import('@29cm/contexts-common-views').then((module) => module.MasonryLayout),
  {
    ssr: false,
  },
);
