import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcSettingBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.387 22h3.25v-.01c.29 0 .57-.09.79-.25.24-.16.41-.39.5-.66l.55-1.591c.54-.23 1.05-.52 1.53-.85l1.76.34c.27.05.55.03.82-.09.25-.11.47-.3.61-.54l1.61-2.672c.15-.25.22-.54.19-.83-.03-.3-.16-.57-.36-.791l-1.15-1.24a7.5 7.5 0 0 0 0-1.641l1.15-1.241c.2-.21.33-.49.36-.79.03-.29-.04-.59-.19-.84l-1.61-2.672c-.15-.24-.36-.43-.62-.54q-.39-.165-.81-.09l-1.77.35c-.48-.34-.99-.62-1.53-.85l-.56-1.592c-.1-.26-.27-.49-.5-.66-.23-.16-.51-.25-.79-.25h-3.25c-.28 0-.55.08-.79.25-.23.17-.4.4-.49.66l-.55 1.591c-.54.23-1.05.52-1.53.85l-1.77-.35c-.27-.06-.55-.03-.81.09-.26.11-.47.3-.61.54l-1.62 2.672c-.15.25-.22.56-.19.85.04.29.16.57.36.781l1.15 1.23a7.5 7.5 0 0 0 0 1.641l-1.15 1.251c-.2.22-.32.49-.35.78-.03.3.04.6.19.85l1.61 2.662c.14.24.35.43.62.55.26.12.54.14.81.09l1.77-.35c.48.34.99.62 1.53.85l.56 1.602a1.365 1.365 0 0 0 1.28.91M15 11H9v2h6z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcSettingBoldFill;
