import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcLawRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M12.8 20.2V5.067h4.7a.8.8 0 0 0 0-1.6h-4.7V2.25a.25.25 0 0 0-.25-.25h-1.1a.25.25 0 0 0-.25.25v1.217H6.5a.8.8 0 1 0 0 1.6h4.7V20.2H6.75a.25.25 0 0 0-.25.25v1.1c0 .138.112.25.25.25h10.5a.25.25 0 0 0 .25-.25v-1.1a.25.25 0 0 0-.25-.25z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.274 4.064a.8.8 0 0 0-1.548 0L4.382 9.2H2.2v.8a4.2 4.2 0 0 0 8.4 0v-.8H8.618zM6.964 9.2 6.5 7.427 6.036 9.2zM6.4 12.6a2.6 2.6 0 0 1-2.475-1.8h4.95A2.6 2.6 0 0 1 6.4 12.6M18.274 4.064a.8.8 0 0 0-1.548 0L15.382 9.2H13.2v.8a4.2 4.2 0 0 0 8.4 0v-.8h-1.982zM17.5 7.427l.464 1.773h-.928zM14.925 10.8a2.601 2.601 0 0 0 4.95 0z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcLawRegular;
