import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcCloseRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.434 4.434a.8.8 0 0 1 1.132 0L12 10.87l6.434-6.435a.8.8 0 0 1 1.132 1.132L13.13 12l6.435 6.434a.8.8 0 0 1-1.132 1.132L12 13.13l-6.434 6.435a.8.8 0 0 1-1.132-1.132L10.87 12 4.434 5.566a.8.8 0 0 1 0-1.132"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcCloseRegular;
