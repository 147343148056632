import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcFilterDotRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.84 7.292h-3.18v-1.6h3.18zM12.676 7.292H5.16v-1.6h7.516zM18.84 12.8H5.16v-1.6h13.68z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.168 5.8a.692.692 0 1 0 0 1.384.692.692 0 0 0 0-1.384m-2.292.692a2.292 2.292 0 1 1 4.584 0 2.292 2.292 0 0 1-4.584 0M5.16 16.708h3.18v1.6H5.16zM11.324 16.708h7.516v1.6h-7.516z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.832 16.816a.692.692 0 1 0 0 1.384.692.692 0 0 0 0-1.384m-2.292.692a2.292 2.292 0 1 1 4.584 0 2.292 2.292 0 0 1-4.584 0"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcFilterDotRegular;
