import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcPictureBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.5 2.5a1 1 0 0 0-1 1v11.289l4.877-5.527a1.5 1.5 0 0 1 2.245-.004L20.505 21.5a1 1 0 0 0 .995-1v-17a1 1 0 0 0-1-1zm12.5 8a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"
        clipRule="evenodd"
      />
      <path fill="currentColor" d="M2.5 20.5v-2.689l6.003-6.803L17.829 21.5H3.5a1 1 0 0 1-1-1" />
    </svg>
  );
});
export default SvgIcPictureBoldFill;
