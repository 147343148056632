'use client';

import { Banners } from '@29cm/contexts-banners-services';
import { Responsive } from '@29cm/contexts-common-views';
import { trackVisitHomeMainPageEvent } from '@29cm/contexts-events/services';
import { Feeds } from '@29cm/contexts-feeds-services';
import { Divider } from '@29cm/contexts-home/views';
import { Result } from '@29cm/contexts-http/interfaces';
import { useEffectOnce } from '@29cm/hooks-effects';
import { once } from '@29cm/utils-functions';
import { useCallback, useState } from 'react';
import { tv } from 'tailwind-variants';
import { BannerCarousel } from '../BannerCarousel';
import { BannerList } from '../BannerList';
import { FeedList } from '../FeedList';
import { FeedMasonryLayout } from '../FeedMasonryLayout';
import { ReadMoreSection } from '../ReadMoreSection';

interface Props {
  mobileBanners: Result<Banners, Error>;
  desktopBanners: Result<Banners, Error>;
  mobileFeeds: Result<Feeds, Error>;
  desktopFeeds: Result<Feeds, Error>;
}

const homeContentStyles = tv({
  base: 'relative flex flex-col md:flex-row',
  variants: {
    expand: {
      false: `max-h-[12000px] overflow-hidden`,
    },
  },
});

export const Home = ({ mobileBanners, desktopBanners, mobileFeeds, desktopFeeds }: Props) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const trackEvent = useCallback(once(trackVisitHomeMainPageEvent), []);

  useEffectOnce(() => trackEvent({}));

  const [expand, setExpand] = useState(false);

  const shouldRenderReadMoreSection = !expand;

  const handleReadMoreButtonClick = () => {
    setExpand(true);
  };

  return (
    <div className="flex flex-col">
      <div className={homeContentStyles({ expand })}>
        <Responsive
          md={<Divider className="absolute bottom-0 left-[44%] top-0 z-content-1 h-full" direction="vertical" />}
        />
        <Responsive
          md={<Divider className="absolute bottom-0 right-[28%] top-0 z-content-1 h-full" direction="vertical" />}
        />

        <Responsive
          className="p-0 md:w-[44%] md:pr-[2%]"
          sm={<BannerCarousel initialBanners={mobileBanners} />}
          md={<BannerList initialBanners={desktopBanners} />}
        />
        <Responsive
          className="md:w-[56%]"
          sm={<FeedList initialFeeds={mobileFeeds} />}
          md={<FeedMasonryLayout initialFeeds={desktopFeeds} />}
        />

        {shouldRenderReadMoreSection ? (
          <ReadMoreSection className="absolute inset-x-0 bottom-0 z-content-1" onClick={handleReadMoreButtonClick} />
        ) : null}
      </div>
    </div>
  );
};
