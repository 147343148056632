import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcEyeOnRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.994 8.094a3.906 3.906 0 1 0 0 7.812 3.906 3.906 0 0 0 0-7.812M9.688 12a2.306 2.306 0 1 1 4.612 0 2.306 2.306 0 0 1-4.612 0"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.994 5.2c-4.086 0-7.851 2.494-9.95 6.423l-.2.377.2.377c2.099 3.93 5.864 6.423 9.95 6.423 4.088 0 7.853-2.494 9.95-6.423l.202-.377-.201-.377c-2.098-3.93-5.863-6.423-9.95-6.423m0 12c-3.284 0-6.436-1.937-8.328-5.2 1.892-3.263 5.044-5.2 8.328-5.2 3.286 0 6.438 1.937 8.33 5.2-1.892 3.262-5.044 5.2-8.33 5.2"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcEyeOnRegular;
