import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcEyeOnBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.994 7.894a4.106 4.106 0 1 0 0 8.212 4.106 4.106 0 0 0 0-8.212M9.888 12a2.106 2.106 0 1 1 4.212 0 2.106 2.106 0 0 1-4.212 0"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.994 5C7.823 5 3.995 7.545 1.868 11.53l-.251.47.251.47C3.995 16.456 7.823 19 11.994 19c4.172 0 8-2.545 10.127-6.53l.252-.47-.252-.47C19.994 7.544 16.167 5 11.994 5m0 12c-3.17 0-6.229-1.85-8.096-5 1.867-3.15 4.927-5 8.096-5s6.23 1.851 8.098 5c-1.868 3.149-4.927 5-8.098 5"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcEyeOnBold;
