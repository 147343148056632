import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcSpaceBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <g className="ic-29space-bold">
        <g fill="currentColor" className="vector">
          <path
            fillRule="evenodd"
            d="M11.215 3.297a1.186 1.186 0 0 1 1.57 0l8.313 7.34c.256.225.402.55.402.892v8.282c0 .657-.532 1.189-1.188 1.189H3.689A1.19 1.19 0 0 1 2.5 19.81v-8.28c0-.342.147-.667.402-.893zM12 5.141l-7.6 6.71v7.246h15.2V11.85L12 5.14Z"
            clipRule="evenodd"
          />
          <path d="M9.709 13.58a.56.56 0 0 0 .152-.383.616.616 0 1 0-1.23 0v.38H7.097v-.38c0-1.168.974-2.093 2.148-2.093s2.147.925 2.147 2.093c0 .511-.196 1.004-.548 1.374L9.393 16.1h2v1.476H7.098v-1.247z" />
          <path
            fillRule="evenodd"
            d="M12.303 13.352c0 1.051.75 1.917 1.74 2.143l-1.469 2.08h1.878l2.082-3.015c.245-.355.376-.777.376-1.208 0-1.253-1.042-2.248-2.303-2.248s-2.304.995-2.304 2.248m1.532 0a.772.772 0 1 0 1.544.001.772.772 0 0 0-1.544 0Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
});
export default SvgIcSpaceBold;
