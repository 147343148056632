// TODO(byungcheollee): imageLoader 함수를 packages로 옮기기
'use client';

const { imageHost } = require('@29cm/contexts-common-constants');

const baseUrl = `https://${imageHost}`;

export default function imageLoader({ src, width, quality = 75 }) {
  const srcUrl = new URL(src, baseUrl);
  if (srcUrl.host !== imageHost) {
    // img.29cm.co.kr 주소가 아닌 이미지는 그대로 반환
    return src;
  }

  srcUrl.search = '';
  srcUrl.searchParams.set('width', width);
  srcUrl.searchParams.set('q', quality);
  srcUrl.searchParams.set('format', 'auto');
  return srcUrl.href;
}
