import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcPencilBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M19.012 4.904a4 4 0 0 0-5.657 0l-7.778 7.779a1 1 0 0 0-.283.566l-.66 4.62a1.25 1.25 0 0 0 1.415 1.413l4.62-.66c.211-.03.41-.128.565-.283l7.778-7.778a4 4 0 0 0 0-5.657M14.77 6.32a2 2 0 1 1 2.828 2.828l-.707.707-2.829-2.828zM12.648 8.44l-5.421 5.421-.471 3.3 3.3-.471 5.42-5.422z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcPencilBold;
