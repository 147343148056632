import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcSearchListBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2 7V5h5v2zM2 19v-2h8v2zM2 13v-2h5v2zM14.659 6a4.659 4.659 0 1 0 0 9.318 4.659 4.659 0 0 0 0-9.318M8 10.659a6.659 6.659 0 1 1 12.017 3.955l2.689 2.677-1.412 1.418-2.693-2.683A6.659 6.659 0 0 1 8 10.66"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcSearchListBold;
