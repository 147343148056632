import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcShareBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16.5 2a4 4 0 0 0-3.848 5.096l-1.908 1.041-1.909 1.041a4 4 0 1 0 0 5.644l1.908 1.04 1.909 1.042A4.004 4.004 0 0 0 16.5 22a4 4 0 1 0-2.835-6.822l-1.909-1.04-1.908-1.042a4 4 0 0 0 0-2.192l1.909-1.041 1.908-1.041A4 4 0 1 0 16.5 2M4 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0m10.5-6a2 2 0 1 1 4 0 2 2 0 0 1-4 0m0 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcShareBold;
