import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcDeliveryBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14 3a1.5 1.5 0 0 1 1.5-1.5H22v2h-6V19h6v2h-9.627A3.5 3.5 0 0 1 9.5 22.5c-1.19 0-2.24-.593-2.873-1.5H3.5A1.5 1.5 0 0 1 2 19.5v-5.653a1.5 1.5 0 0 1 .973-1.405l3.288-1.233 2.195-4.83A1.5 1.5 0 0 1 9.822 5.5H14zM8 19a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0m5 0h1v-6H7.181L4 14.193V19h2a3.5 3.5 0 1 1 7 0m1-11.5h-3.856L8.553 11H14z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcDeliveryBold;
