import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcCheckCalendarRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="m15.093 8.993-3.887 4.743-2.276-3.063-1.284.954 2.887 3.886a.8.8 0 0 0 1.26.03l4.537-5.536z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.324 4.186V2.102h-1.6v2.084H4.5c-.717 0-1.3.583-1.3 1.3v13.912c0 .717.583 1.3 1.3 1.3h15c.717 0 1.3-.583 1.3-1.3V5.486c0-.717-.583-1.3-1.3-1.3h-2.346V2.102h-1.6v2.084zM4.8 19.098V5.786h14.4v13.312z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcCheckCalendarRegular;
