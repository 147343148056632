import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcFoldChevronLeftRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path fill="currentColor" d="m5 12 8.6-8.6h2.263l-8.6 8.6 8.6 8.6H13.6z" />
      <path fill="currentColor" d="m19.863 20.6-8.6-8.6 8.6-8.6H17.6L9 12l8.6 8.6z" />
    </svg>
  );
});
export default SvgIcFoldChevronLeftRegular;
