import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcFoldChevronRightLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path fill="currentColor" d="m19 12-8.4-8.4H9.186l8.4 8.4-8.4 8.4H10.6z" />
      <path fill="currentColor" d="m5.186 20.4 8.4-8.4-8.4-8.4H6.6L15 12l-8.4 8.4z" />
    </svg>
  );
});
export default SvgIcFoldChevronRightLight;
