import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcMil = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2m0 1.6a8.4 8.4 0 1 0 0 16.8 8.4 8.4 0 0 0 0-16.8"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="m7.486 12.43.037-.13 1.179-4.119A.25.25 0 0 1 8.942 8h1.491a.25.25 0 0 1 .24.181l1.18 4.119.147.514 1.326-4.633a.25.25 0 0 1 .24-.181h1.492a.25.25 0 0 1 .24.181l1.18 4.119.68 2.381a.25.25 0 0 1-.24.319h-1.04a.25.25 0 0 1-.24-.181L14.46 10.7l-.147-.514-.147.514-1.18 4.119a.25.25 0 0 1-.24.181h-1.49a.25.25 0 0 1-.24-.181L9.834 10.7l-.147-.514-.147.514-1.18 4.119a.25.25 0 0 1-.24.181H7.08a.25.25 0 0 1-.24-.319l.645-2.25Z"
      />
    </svg>
  );
});
export default SvgIcMil;
