import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcEyeOnLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.994 8.394a3.606 3.606 0 1 0 0 7.212 3.606 3.606 0 0 0 0-7.212M9.388 12a2.606 2.606 0 1 1 5.212 0 2.606 2.606 0 0 1-5.212 0"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.994 5.5c-3.96 0-7.631 2.418-9.685 6.265L2.184 12l.125.236c2.054 3.846 5.725 6.264 9.685 6.264s7.632-2.418 9.686-6.264l.126-.236-.126-.236C19.626 7.919 15.955 5.5 11.994 5.5m0 12c-3.458 0-6.747-2.068-8.674-5.5 1.927-3.431 5.216-5.5 8.674-5.5 3.459 0 6.748 2.069 8.675 5.5-1.927 3.431-5.216 5.5-8.675 5.5"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcEyeOnLight;
