import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcDocumentLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.5 4.25a.75.75 0 0 1 .75-.75h15.5a.75.75 0 0 1 .75.75v15.5a.75.75 0 0 1-.75.75H4.25a.75.75 0 0 1-.75-.75zm1 .25v15h15v-15z"
        clipRule="evenodd"
      />
      <path fill="currentColor" fillRule="evenodd" d="M8 7.5h8v1H8zM8 11.5h8v1H8zM8 15.5h8v1H8z" clipRule="evenodd" />
    </svg>
  );
});
export default SvgIcDocumentLight;
