import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcGiftBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M12.8 21.399h5.95c.58 0 1.05-.47 1.05-1.05v-9.95h-7zM12.8 9.399h7.95a.75.75 0 0 0 .75-.75v-1.5a.75.75 0 0 0-.75-.75H12.8zM11.2 6.399v3H3.25a.75.75 0 0 1-.75-.75v-1.5a.75.75 0 0 1 .75-.75zM11.2 10.399v11H5.25c-.58 0-1.05-.47-1.05-1.05v-9.95zM13.467 4.014A19 19 0 0 0 12 5.267c-.461-.432-.96-.866-1.467-1.253-.72-.549-1.52-1.05-2.297-1.28-.8-.237-1.751-.22-2.444.545-.45.496-.695 1.216-.792 2.12h14c-.096-.904-.342-1.624-.792-2.12-.693-.764-1.643-.782-2.444-.545-.777.23-1.577.731-2.297 1.28"
      />
    </svg>
  );
});
export default SvgIcGiftBoldFill;
