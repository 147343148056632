import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcArrowLeftBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.707 4.043a1 1 0 0 1 0 1.414L5.914 11.25H20.5a1 1 0 1 1 0 2H6.037l5.645 5.269a1 1 0 0 1-1.364 1.462l-7.5-7a1 1 0 0 1-.025-1.438l7.5-7.5a1 1 0 0 1 1.414 0"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcArrowLeftBold;
