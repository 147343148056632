import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcArrowRightBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.293 19.957a1 1 0 0 1 0-1.414l5.793-5.793H3.5a1 1 0 1 1 0-2h14.463l-5.645-5.269a1 1 0 0 1 1.364-1.462l7.5 7a1 1 0 0 1 .025 1.438l-7.5 7.5a1 1 0 0 1-1.414 0"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcArrowRightBold;
