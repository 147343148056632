import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcBoxBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M8.824 3v9h1.058V3h4.236v9h1.059V3h4.764C20.526 3 21 3.474 21 4.059V19.94c0 .585-.474 1.059-1.059 1.059H4.06A1.06 1.06 0 0 1 3 19.941V4.06C3 3.474 3.474 3 4.059 3z"
      />
    </svg>
  );
});
export default SvgIcBoxBoldFill;
