import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcStarHalfLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="m4.075 10.333 5.513-.841L12 4.353V2L8.91 8.584 2 9.639l5 5.125L5.82 22 12 18.583V17.44l-4.862 2.688.93-5.701z"
      />
    </svg>
  );
});
export default SvgIcStarHalfLight;
