/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createAnalytics } from './createAnalytics';

type GoogleAnalyticsFunctionOptions = {
  hitType: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  value?: number;
};

type GoogleAnalyticsFunction = (type: string, options: GoogleAnalyticsFunctionOptions) => void;

type WindowWithGoogleAnalytics = Window & {
  ga?: GoogleAnalyticsFunction;
};

const GOOGLE_ANALYTICS_ID = 'GTM-KT59HDJ';

export const googleAnalytics = createAnalytics<
  GoogleAnalyticsFunction | undefined,
  string,
  Omit<GoogleAnalyticsFunctionOptions, 'hitType' | 'eventCategory'>
>({
  platform: 'googleAnalytics',
  initializer: async () => {
    // FIXME: Webview 에서 실행되지 않도록 처리
    return loadGoogleAnalyticsScript();
  },
  tracker: (instance, name, properties) => {
    instance?.('send', { ...(properties as GoogleAnalyticsFunctionOptions), hitType: 'event', eventCategory: name });
  },
});

const loadGoogleAnalyticsScript = (): Promise<GoogleAnalyticsFunction | undefined> => {
  return new Promise<GoogleAnalyticsFunction | undefined>((resolve) => {
    (function (w, d, s, l, i) {
      // @ts-ignore
      w[l] = w[l] || [];
      // @ts-ignore
      w[l].push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' });
      const f = d.getElementsByTagName(s)[0];
      const j = <HTMLScriptElement>d.createElement(s);
      const dl = l != 'dataLayer' ? `&l=${l}` : '';
      j.async = true;
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
      j.onload = function () {
        resolve((window as WindowWithGoogleAnalytics).ga);
      };
      // @ts-ignore
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', GOOGLE_ANALYTICS_ID);
  });
};
