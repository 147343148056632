import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcArrowLeftRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.066 4.184a.8.8 0 0 1 0 1.132L4.93 11.45H21a.8.8 0 0 1 0 1.6H5.03l6.016 5.615a.8.8 0 1 1-1.092 1.17l-7.5-7a.8.8 0 0 1-.02-1.15l7.5-7.5a.8.8 0 0 1 1.132 0"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcArrowLeftRegular;
