import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcDocumentBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.25 3C3.56 3 3 3.56 3 4.25v15.5c0 .69.56 1.25 1.25 1.25h15.5c.69 0 1.25-.56 1.25-1.25V4.25C21 3.56 20.44 3 19.75 3zM16.3 7.2H7.7v1.6h8.6zm-8.6 4h8.6v1.6H7.7zm8.6 4H7.7v1.6h8.6z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcDocumentBoldFill;
