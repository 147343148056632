import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcLockBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path fill="currentColor" d="M13 13.5a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0z" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6 8a6 6 0 1 1 12 0v2h1.25a.75.75 0 0 1 .75.75v10.5a.75.75 0 0 1-.75.75H4.75a.75.75 0 0 1-.75-.75v-10.5a.75.75 0 0 1 .75-.75H6zm10 0v2H8V8a4 4 0 1 1 8 0M6 12v8h12v-8z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcLockBold;
