import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcCautionLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M11.5 13.5v-7h1v7zM11 16.51c0-.552.458-1 1.01-1.01.541.01.99.458.99 1.01 0 .541-.449 1-.99.99a1 1 0 0 1-1.01-.99"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-1 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcCautionLight;
