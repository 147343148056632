import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcShareLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16.5 2.5a3.5 3.5 0 0 0-3.237 4.833L10.995 8.57 8.727 9.806a3.5 3.5 0 1 0 0 4.387l2.268 1.237 2.268 1.237a3.5 3.5 0 1 0 .51-.86l-2.268-1.238-2.268-1.236c.17-.411.263-.861.263-1.333a3.5 3.5 0 0 0-.263-1.333l2.268-1.237 2.268-1.236A3.5 3.5 0 1 0 16.5 2.5M3.5 12a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0M14 6a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0m0 12a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcShareLight;
