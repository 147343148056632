import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcCommentRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.3 4.6v11.433h13.375l2.025 1.633V4.6zm-1.6-.55C2.7 3.47 3.17 3 3.75 3h16.5c.58 0 1.05.47 1.05 1.05v16.962l-4.19-3.379H3.75c-.58 0-1.05-.47-1.05-1.05z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M8.147 9.31a1.007 1.007 0 1 0 0 2.013 1.007 1.007 0 0 0 0-2.013M12 9.31a1.007 1.007 0 1 0 0 2.013 1.007 1.007 0 0 0 0-2.013M15.853 9.31a1.007 1.007 0 1 0 0 2.013 1.007 1.007 0 0 0 0-2.013"
      />
    </svg>
  );
});
export default SvgIcCommentRegular;
