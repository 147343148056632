import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcMyBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path fill="currentColor" fillRule="evenodd" d="M13 3.25v-1h-2v7h2v-6" clipRule="evenodd" />
      <path fill="currentColor" d="M21 21.75H3V12c0-.695.564-1.25 1.25-1.25h15.5c.69 0 1.25.56 1.25 1.25z" />
    </svg>
  );
});
export default SvgIcMyBoldFill;
