/* eslint-disable @typescript-eslint/no-explicit-any */
export const once = <T extends (...args: any[]) => any>(callback: T) => {
  const map = new Map<string, boolean>();

  return (...args: Parameters<T>) => {
    const key = JSON.stringify(args);

    const isExecuted = map.get(key);

    if (isExecuted) {
      return;
    }

    callback(...args);
    map.set(key, true);
  };
};
