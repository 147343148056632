import { BooleanNumber, BooleanString } from '@29cm/contexts-http/interfaces';
import { wrapApiResponse } from '@29cm/contexts-http/utils';
import * as v from 'valibot';
import { ExternalServiceType, FeedVariant, HomeType, ImageType, LinkType } from '../enums';

export const FeedsResponse = wrapApiResponse(
  v.object({
    count: v.number(),
    next: v.nullable(v.string()),
    previous: v.nullable(v.string()),
    results: v.array(
      v.object({
        feedNo: v.number(),
        feedType: v.enum(FeedVariant),
        viewStartTimestamp: v.string(),
        viewEndTimestamp: v.nullable(v.string()),
        isViewEnd: v.enum(BooleanString),
        homeType: v.array(v.enum(HomeType)),
        videoType: v.nullable(v.string()),
        videoUrl: v.string(),
        feedTitle: v.string(),
        feedContents: v.string(),
        adminNo: v.number(),
        linkType: v.enum(LinkType),
        linkValue: v.string(),
        insertTimestamp: v.string(),
        updatedTimestamp: v.string(),
        isDeleted: v.enum(BooleanString),
        isUse: v.enum(BooleanString),
        orderingIdx: v.number(),
        feedSort: v.string(),
        relatedFeedFileList: v.array(
          v.object({
            fileNo: v.number(),
            fileSize: v.number(),
            fileExtension: v.string(),
            fileUploadName: v.string(),
            insertTimestamp: v.string(),
            isDeleted: v.enum(BooleanString),
            widthNo: v.number(),
            heightNo: v.number(),
            alignNo: v.number(),
            adminNo: v.nullable(v.number()),
          }),
        ),
        relatedFeedItemList: v.array(
          v.object({
            feedItemNo: v.number(),
            itemNo: v.object({
              saleInfoV2: v.object({
                consumerPrice: v.number(),
                sellPrice: v.number(),
                saleRate: v.number(),
                couponSaleRate: v.number(),
                isCoupon: v.boolean(),
                totalSellPrice: v.number(),
                totalSaleRate: v.number(),
              }),
              saleInfo: v.nullable(
                v.object({
                  consumerPrice: v.number(),
                  totalSalePrice: v.number(),
                  totalSalePercent: v.number(),
                  isPossibleCouponSale: v.enum(BooleanNumber),
                }),
              ),
              itemNo: v.number(),
              itemName: v.string(),
              isLocalGoods: v.nullable(v.enum(BooleanString)),
              frontBrand: v.object({
                brandNameEng: v.string(),
                brandNameKor: v.string(),
                frontBrandNo: v.number(),
              }),
              brandName: v.string(),
              heartCount: v.number(),
              reviewCount: v.number(),
              itemImages: v.array(
                v.object({
                  fileUploadName: v.string(),
                  widthNo: v.nullable(v.number()),
                  heightNo: v.nullable(v.number()),
                  imageType: v.enum(ImageType),
                }),
              ),
              itemSubjects: v.array(
                v.object({
                  itemSubjectNo: v.nullable(v.number()),
                  subjectVisibleBeginTimestamp: v.nullable(v.string()),
                  subjectVisibleEndTimestamp: v.nullable(v.string()),
                  useWithoutSubjectVisibleEndDate: v.nullable(v.enum(BooleanString)),
                  adminNo: v.nullable(v.number()),
                  externalServiceType: v.nullable(v.enum(ExternalServiceType)),
                  subjectDescription: v.string(),
                  orderingIdx: v.nullable(v.number()),
                }),
              ),
            }),
            insertTimestamp: v.nullable(v.string()),
            updatedTimestamp: v.nullable(v.string()),
            adminNo: v.nullable(v.number()),
            alignNo: v.number(),
            isDeleted: v.nullable(v.enum(BooleanString)),
          }),
        ),
        curator: v.nullable(
          v.object({
            curatorNo: v.number(),
            name: v.string(),
            thumbnail: v.string(),
            personality: v.string(),
          }),
        ),
      }),
    ),
  }),
);

export type FeedsResponse = v.InferInput<typeof FeedsResponse>;
