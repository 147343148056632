import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcScrapBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20.003 3.682V3.67a.68.68 0 0 0-.203-.47.7.7 0 0 0-.479-.2H4.694a.683.683 0 0 0-.69.681L4 21l8-4.942L20 21l-.002-.681zM18 17.413 18.003 5H6.004l-.003 12.413L12 13.707z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcScrapBold;
