import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcInfoCircleBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-11.1-1.35v5.863c0 .138.112.25.25.25h1.715a.25.25 0 0 0 .25-.25V10.65a.25.25 0 0 0-.25-.25H11.15a.25.25 0 0 0-.25.25M12 9.9a1.15 1.15 0 1 0 0-2.3 1.15 1.15 0 0 0 0 2.3"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcInfoCircleBoldFill;
