import { Properties } from '@29cm/contexts-analytics/interfaces';

type EventOptions<K extends string, P extends Properties> = {
  /**
   * @description 수집할 이벤트명
   * @example 'visit_home_main'
   */
  name: K;
  /**
   * @description 플랫폼별 이벤트 수집 함수 목록
   *
   * analytics.track 함수를 배열에 담아 전달합니다.
   */
  trackers: ((name: K, properties: P) => void)[];
};

/**
   * @description 이벤트명, 이벤트 프로퍼티등을 type-safe 하게 관리하고, 복수의 플랫폼 적재 시에도 하나의 함수만 실행하도록 편의성을 제공하는 함수입니다.
   * 
   * @example
   * ```ts
   * const trackVisitHomeMain = createEvent({
      name: 'visit_home_main',
      trackers: [
        // 이벤트 프로퍼티를 생략 가능한 경우
        firebase.track,

        // 하드코딩할 이벤트 프로퍼티가 존재하는 경우
        amplitude.track({ curr_screen: 'home_main' }),

        // 이벤트 프로퍼티 전달을 위해 사용처에서의 주입이 필요한 경우
        braze.track((properties) => ({ curr_screen: properties.screen }))
      ],
    });

    trackVisitHomeMain({ screen: 'home_main' })
   * 
   * ```
   */
export const createEvent = <P extends Properties = Properties, K extends string = string>(
  options: EventOptions<K, P>,
) => {
  const { name, trackers } = options;

  return (properties: P) => trackers.forEach((tracker) => tracker(name, properties));
};
