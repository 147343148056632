import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcScrapBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M4.005 3.646c0-.349.296-.646.69-.646H19.32c.18.002.352.071.479.19a.63.63 0 0 1 .203.445v.011l-.005 16.708L20 21l-8-4.685L4 21v-.646z"
      />
    </svg>
  );
});
export default SvgIcScrapBoldFill;
