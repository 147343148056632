import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcCautionRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M11.2 6.5h1.6v7h-1.6zM10.75 16.262c0-.69.572-1.25 1.262-1.262a1.267 1.267 0 0 1 1.238 1.262c0 .677-.56 1.25-1.238 1.238a1.25 1.25 0 0 1-1.262-1.238"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-1.6 0a8.4 8.4 0 1 1-16.8 0 8.4 8.4 0 0 1 16.8 0"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcCautionRegular;
