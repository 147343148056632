import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcGiftBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.709 3.957c.445.352.882.74 1.291 1.132.41-.391.846-.78 1.291-1.132.701-.554 1.496-1.073 2.281-1.314.815-.25 1.827-.242 2.568.604.474.542.72 1.315.814 2.251h1.796c.69 0 1.25.56 1.25 1.25v2.5c0 .69-.56 1.25-1.25 1.25H20v9.75c0 .69-.56 1.25-1.25 1.25H5.25c-.69 0-1.25-.56-1.25-1.25v-9.75h-.75c-.69 0-1.25-.56-1.25-1.25v-2.5c0-.69.56-1.25 1.25-1.25h1.796c.094-.936.34-1.71.814-2.251.74-.846 1.753-.854 2.568-.604.785.24 1.58.76 2.28 1.314M20 8.498h-7v-1h7zm-9-1v1H4v-1zm0 12v-9H6v9zm2-9h5v9h-5zm3.94-5c-.079-.568-.216-.832-.306-.934l-.004-.005c-.027-.033-.096-.12-.471-.004-.419.128-.967.453-1.592.943zm-7.507 0H7.06c.078-.568.216-.832.306-.934l.004-.005c.027-.033.097-.12.471-.004.419.128.967.453 1.592.943"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcGiftBold;
