import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcWonBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18 13.25a.25.25 0 0 1-.25.25h-1.67l-.6 2.313a.25.25 0 0 1-.242.187h-2.065a.25.25 0 0 1-.241-.184l-.633-2.316h-.598l-.633 2.316a.25.25 0 0 1-.24.184H8.761a.25.25 0 0 1-.241-.187L7.919 13.5H6.25a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25H7.4L6.83 9.313A.25.25 0 0 1 7.073 9h1.254a.25.25 0 0 1 .24.184L9.2 11.5h1.187l.632-2.316A.25.25 0 0 1 11.261 9h1.478a.25.25 0 0 1 .242.184l.632 2.316H14.8l.632-2.316A.25.25 0 0 1 15.673 9h1.254a.25.25 0 0 1 .242.313L16.6 11.5h1.15a.25.25 0 0 1 .25.25zm-3.747.25h-.093l.046.171zm-4.413 0h-.093l.047.171z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10m0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcWonBold;
