import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcLockRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path fill="currentColor" d="M12.8 13.5a.8.8 0 0 0-1.6 0v2a.8.8 0 0 0 1.6 0z" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.2 8a5.8 5.8 0 1 1 11.6 0v2h1.45a.75.75 0 0 1 .75.75v10.5a.75.75 0 0 1-.75.75H4.75a.75.75 0 0 1-.75-.75v-10.5a.75.75 0 0 1 .75-.75H6.2zm10 0v2H7.8V8a4.2 4.2 0 0 1 8.4 0M5.6 11.6v8.8h12.8v-8.8z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcLockRegular;
