import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcBelloffBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m17.1 4.449 1.49-1.39 1.364 1.463-16.09 15.004L2.5 18.064l.858-.8h-.013l1.862-3.881v-4.57c0-1.802.705-3.533 1.965-4.812S10.145 2 11.934 2A6.68 6.68 0 0 1 17.1 4.449m-1.829.955q.194.197.362.413l-8.426 7.857v-4.86c0-1.282.502-2.508 1.39-3.41A4.68 4.68 0 0 1 11.934 4c1.249 0 2.45.503 3.337 1.404"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M9.222 17.263h11.301l-1.862-3.88v-4.57q0-.173-.009-.344l-1.991 1.857v3.512l.684 1.425h-5.979zM7.705 18.678C7.99 20.648 9.888 22 11.934 22c2.221 0 4.267-1.594 4.267-3.842h-2c0 .891-.88 1.842-2.267 1.842s-2.267-.95-2.267-1.842H8.262z"
      />
    </svg>
  );
});
export default SvgIcBelloffBold;
