import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcBagBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 1a5 5 0 0 0-4.899 4H4.202C3.527 5 3 5.544 3 6.2v14.624A1.205 1.205 0 0 0 4.188 22h15.61c.675 0 1.202-.544 1.202-1.2v-6.3h-9v-2h9V6.2c0-.647-.53-1.2-1.202-1.2h-2.899A5 5 0 0 0 12 1m2.121 2.879c.32.32.56.703.707 1.121H9.172a3 3 0 0 1 4.95-1.121"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcBagBoldFill;
