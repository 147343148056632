import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcLawBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13 5.267V20h4.25a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25H6.75a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25H11V5.267H7.795L8.772 9H10.8v1A4.4 4.4 0 1 1 2 10V9h2.228l1.3-4.97a1 1 0 0 1 .367-.56 1 1 0 0 1 .605-.203H11V2.25a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25v1.017h4.5a1 1 0 0 1 .974.77L19.772 9H22v1a4.4 4.4 0 0 1-8.8 0V9h2.028l.977-3.733zm-6.5 2.95L6.705 9h-.41zM4.218 11a2.4 2.4 0 0 0 4.364 0zm13.487-2-.205-.782-.205.782zm-.105 3.4a2.4 2.4 0 0 1-2.182-1.4h4.364a2.4 2.4 0 0 1-2.182 1.4"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcLawBold;
