import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcQuestionBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-8.515-2.384c.007.794-.5 1.327-1.154 1.764q-.172.107-.334.203c-.813.483-1.437.854-1.45 2.642v.143h1.8v-.143c.012-1.001.449-1.257 1.117-1.648q.191-.11.405-.243c1-.612 1.623-1.455 1.631-2.75-.008-1.892-1.43-3.084-3.508-3.084-1.9 0-3.438 1.089-3.492 3.227h1.923c.054-1.065.792-1.574 1.554-1.574.838 0 1.515.572 1.508 1.463M12.48 15.5a1.2 1.2 0 0 0-.919-.464 1.22 1.22 0 0 0-1.2 1.224c-.004.345.133.657.355.882.217.221.517.358.845.358.365 0 .7-.181.919-.464.165-.213.266-.483.266-.776 0-.286-.1-.55-.266-.76"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcQuestionBoldFill;
