'use client';

import { Intersection } from '@29cm/contexts-common-features';
import { useBodyScrollLock, useIsSmScreen } from '@29cm/contexts-common-hooks';
import { trackClickCouponTriggerEvent, trackViewCouponTriggerEvent } from '@29cm/contexts-events/services';
import { APP_INSTALL_LAYER_DATA, APP_INSTALL_LAYER_ONE_TIME_DATA } from '@29cm/contexts-home/constants';
import { useCloseAppInstallBottomSheetFor24HoursStore } from '@29cm/contexts-persistent-state/hooks';
import { generateAppInstallOneLink } from '@29cm/contexts-smart-link/services';
import { Button, TextButton } from '@29cm/ruler';

export const HomeMainAppInstallLayer = () => {
  const isSmScreen = useIsSmScreen();

  const { closeFor24Hours, setCloseFor24Hours } = useCloseAppInstallBottomSheetFor24HoursStore();

  const shouldShowAppInstallLayer = isSmScreen && !closeFor24Hours;

  useBodyScrollLock(shouldShowAppInstallLayer);

  // TODO:(이조은, ~24.8.29): 8월 29일에만 데이터를 변경합니다. 이후에는 브레이즈로 데이터를 보여줄 예정이기 때문에 일회성으로 대응합니다.
  const useHomeMainAppInstallLayerData = () => {
    const today = new Date();
    const targetDate = new Date('2024-08-29');

    today.setHours(0, 0, 0, 0);
    targetDate.setHours(0, 0, 0, 0);

    const isTodayTargetDate = today.getTime() === targetDate.getTime();

    return isTodayTargetDate ? APP_INSTALL_LAYER_ONE_TIME_DATA : APP_INSTALL_LAYER_DATA;
  };

  const { title, imageUrl, buttonText } = useHomeMainAppInstallLayerData();

  if (!shouldShowAppInstallLayer) {
    return null;
  }

  const trackView = () => trackViewCouponTriggerEvent({});

  const trackClick = () => trackClickCouponTriggerEvent({});

  const handleAppInstallButtonClick = () => {
    trackClick();
    setCloseFor24Hours();
    document.location.href = generateAppInstallOneLink('INSTALL_LAYER');
  };

  const handleClose = () => {
    setCloseFor24Hours();
  };

  return (
    <Intersection onView={trackView}>
      <div className="fixed inset-0 z-popup flex items-end justify-stretch bg-[#00000080] md:hidden">
        <div className="pb-safe flex flex-1 flex-col items-stretch rounded-t-20 bg-on-white px-20 pt-30">
          <p className="whitespace-pre-line px-10 text-start text-title-l-bold text-primary">{title}</p>

          <div className="mx-auto mb-[19px] mt-[14px] h-[98px] w-[201px]">
            <img src={`${imageUrl}?width=402`} className="h-full object-cover" />
          </div>

          <div className="mb-20 flex flex-col gap-8">
            <Button priority="primary" size="small" stretch onClick={handleAppInstallButtonClick}>
              {buttonText}
            </Button>

            <TextButton priority="tertiary" size="small" className="[&>span]:text-s" bold={false} onClick={handleClose}>
              오늘 하루 보지 않기
            </TextButton>
          </div>
        </div>
      </div>
    </Intersection>
  );
};
