import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcSpaceLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <g className="ic-29space-light">
        <g fill="currentColor" className="vector">
          <path
            fillRule="evenodd"
            d="M12.73 13.18c0-1.085.906-1.963 2.024-1.963s2.025.878 2.025 1.962c0 .372-.114.736-.325 1.042l-2.072 2.996h-.995l1.518-2.144-.065.008a1 1 0 0 1-.137.012c-1.09 0-1.974-.857-1.974-1.914Zm.812 0a1.212 1.212 0 1 0 2.424 0 1.212 1.212 0 0 0-2.424 0"
            clipRule="evenodd"
          />
          <path d="M10.971 13.016c0-.994-.833-1.8-1.86-1.8s-1.861.806-1.861 1.8h.813a1.048 1.048 0 1 1 2.096 0 .98.98 0 0 1-.271.678L7.25 16.466v.75h3.721v-.75H8.334l2.17-2.281c.3-.315.467-.734.467-1.169" />
          <path
            fillRule="evenodd"
            d="M12.496 2.987a.75.75 0 0 0-.992 0L2.754 10.7a.75.75 0 0 0-.254.563v8.704c0 .414.336.75.75.75h17.5a.75.75 0 0 0 .75-.75v-8.704a.75.75 0 0 0-.254-.563zM3.5 11.376 12 3.883l8.5 7.493v8.34h-17z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
});
export default SvgIcSpaceLight;
