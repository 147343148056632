/**
 * @description CDN 주소를 전달하면 스크립트 태그를 DOM 에 생성해주는 함수입니다.
 * @param {string} src 스크립트를 로드할 CDN 주소
 * @returns {Promise<void>} Promise 를 반환합니다.
 */
export const loadScript = (src: string): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const isExist = document.querySelector(`script[src="${src}"]`) !== null;

    if (isExist) {
      return resolve();
    }

    const script = document.createElement('script');

    script.src = src;
    script.async = true;
    script.onload = () => resolve();
    script.onerror = () => reject();

    document.body.appendChild(script);
  });
};
