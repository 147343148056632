import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcPictureLight = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path fill="currentColor" d="M15.8 9.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4 3.5a.5.5 0 0 0-.5.5v16a.5.5 0 0 0 .5.5h16a.5.5 0 0 0 .5-.5V4a.5.5 0 0 0-.5-.5zm.5 1v9.1l3.235-3.955a1 1 0 0 1 1.533-.017L17.73 19.5h1.77v-15zm0 15v-4.321l4.009-4.9 7.904 9.221z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcPictureLight;
