import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcFoldChevronRightBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path fill="currentColor" d="m20 12-8.7-8.7H8.472l8.7 8.7-8.7 8.7H11.3z" />
      <path fill="currentColor" d="m4.472 20.7 8.7-8.7-8.7-8.7H7.3L16 12l-8.7 8.7z" />
    </svg>
  );
});
export default SvgIcFoldChevronRightBold;
