import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcStarBold = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m10.267 10.4-4.117.628 2.986 3.062-.68 4.167L12 16.297l3.543 1.96-.68-4.167 2.987-3.062-4.117-.628L12 6.707zM8.91 8.584 2 9.639l5 5.125L5.82 22 12 18.583 18.18 22 17 14.764l5-5.125-6.91-1.055L12 2z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcStarBold;
