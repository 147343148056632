import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcBelloffBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M11.933 22c-2.045 0-3.942-1.352-4.23-3.322l.56-.52H16.2c0 2.248-2.046 3.842-4.267 3.842M20.522 17.263h-11.3l9.43-8.793q.008.17.008.343v4.57zM17.1 4.45A6.684 6.684 0 0 0 11.933 2 6.68 6.68 0 0 0 7.17 4a6.86 6.86 0 0 0-1.965 4.813v4.57l-1.862 3.88h.014l-.858.8 1.364 1.463 16.09-15.004L18.59 3.06z"
      />
    </svg>
  );
});
export default SvgIcBelloffBoldFill;
