import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcShareRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16.5 2.2a3.8 3.8 0 0 0-3.609 4.994L10.844 8.31 8.796 9.427a3.8 3.8 0 1 0 0 5.146l2.048 1.117 2.047 1.116a3.8 3.8 0 1 0 .812-1.38l-2.047-1.116-2.047-1.117a3.8 3.8 0 0 0 0-2.386l2.047-1.117 2.047-1.117A3.8 3.8 0 1 0 16.5 2.2M3.8 12a2.2 2.2 0 1 1 4.4 0 2.2 2.2 0 0 1-4.4 0m10.5-6a2.2 2.2 0 1 1 4.4 0 2.2 2.2 0 0 1-4.4 0m0 12a2.2 2.2 0 1 1 4.4 0 2.2 2.2 0 0 1-4.4 0"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcShareRegular;
