import { useEffect, useState } from 'react';
import { isWebview as getIsWebview } from '../services/isWebview';

/**
 * @description webview 환경 여부를 react state 로 반환합니다.
 */
export const useIsWebview = (initial = false) => {
  const [isWebview, setIsWebview] = useState<boolean>(initial);

  useEffect(() => {
    setIsWebview(getIsWebview());
  }, []);

  return isWebview;
};
