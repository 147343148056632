import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcSettingRegular = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M13.642 21.8h-3.254c-.24 0-.47-.07-.67-.21s-.341-.33-.421-.56l-.591-1.679a9 9 0 0 1-1.652-.91l-1.853.36c-.23.05-.47.02-.69-.08-.23-.1-.411-.26-.531-.47l-1.612-2.658c-.13-.22-.19-.47-.16-.72s.13-.48.3-.67l1.221-1.308q-.105-.885 0-1.77L2.508 9.807c-.17-.18-.28-.42-.3-.67-.03-.25.03-.51.16-.72L3.98 5.749c.12-.2.3-.36.52-.46s.461-.12.691-.08l1.853.36c.51-.36 1.061-.67 1.652-.91l.58-1.678c.08-.23.22-.42.421-.56s.43-.22.67-.22h3.255c.24 0 .47.07.67.21s.341.34.421.56l.581 1.679c.58.24 1.132.55 1.652.91l1.853-.36c.23-.05.47-.02.69.08s.4.26.531.46l1.612 2.668c.13.22.19.47.16.72s-.13.49-.31.68l-1.212 1.309q.105.884 0 1.769l1.222 1.309c.17.19.28.42.3.67.03.25-.03.5-.16.71l-1.612 2.668a1.165 1.165 0 0 1-1.221.54l-1.853-.36c-.51.37-1.071.67-1.652.91l-.58 1.678c-.08.23-.23.42-.421.56s-.43.21-.671.21zm-2.944-1.6h2.634l.73-2.088.361-.13c.67-.24 1.302-.59 1.872-1.03l.29-.22 2.264.44 1.281-2.119-1.522-1.639.06-.39q.165-1.02 0-2.038l-.07-.39 1.522-1.64-1.281-2.118-2.263.44-.29-.22a6.7 6.7 0 0 0-1.873-1.03l-.36-.13-.731-2.089h-2.624l-.73 2.09-.361.13c-.67.239-1.302.589-1.872 1.029l-.29.22-2.264-.44L3.9 8.957l1.522 1.639-.06.39q-.165 1.02 0 2.038l.06.39-1.522 1.64 1.281 2.118 2.263-.44.29.22c.561.44 1.192.79 1.873 1.03l.36.13zm3.925-7.395H9.407v-1.6h5.206v1.6z"
      />
    </svg>
  );
});
export default SvgIcSettingRegular;
