import { SVGProps, forwardRef } from 'react';
type IconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  viewBox?: string;
};
const SvgIcEyeOnBoldFill = forwardRef<SVGSVGElement, IconProps>((iconProps, ref) => {
  const props = {
    viewBox: '0 0 24 24',
    width: iconProps.size ?? 24,
    height: iconProps.size ?? 24,
    ref,
    ...iconProps,
  };
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" role="img">
      <path
        fill="currentColor"
        d="M9.39 12a2.61 2.61 0 0 1 2.604-2.606A2.61 2.61 0 0 1 14.6 12a2.61 2.61 0 0 1-2.605 2.607A2.61 2.61 0 0 1 9.39 12"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.994 5.35c3.968 0 7.73 2.431 9.818 6.344l.163.306-.163.305c-2.089 3.913-5.85 6.345-9.818 6.345s-7.729-2.432-9.817-6.345L2.013 12l.164-.306c2.088-3.913 5.85-6.345 9.817-6.345M8.39 12a3.61 3.61 0 0 0 3.605 3.606A3.61 3.61 0 0 0 15.6 12a3.61 3.61 0 0 0-3.605-3.607A3.61 3.61 0 0 0 8.39 12"
        clipRule="evenodd"
      />
    </svg>
  );
});
export default SvgIcEyeOnBoldFill;
